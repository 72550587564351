import clsx from 'clsx';
import React from 'react';
import useScrollPosition from '../../hooks/useScrollPosition';
import useViewportWidth from '../../hooks/useWindow';
// # assets
import ArrowTop from '../../assets/icons/arrow-top.svg';

function BackToTop({ offset = 400 }) {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const isMobile = useViewportWidth() < 768;
  const scrollPosition = isMobile ? useScrollPosition() * 2 : useScrollPosition();

  return (
    <button
      type="button"
      className={clsx(
        'fixed bottom-3 right-10 z-40 text-center cursor-pointer hidden animate-fade-in',
        scrollPosition > offset && '!inline-block'
      )}
      onClick={scrollToTop}
    >
      <div className="rounded-full inline-block border-2 border-primary hover:bg-primary p-2 sm:p-3">
        <img loading="lazy" src={ArrowTop} className="mb-1 w-[18px] sm:w-[23px]" alt="" />
      </div>
      <p className="font-primary font-light text-12px mt-3">Back to top</p>
    </button>
  );
}

export default BackToTop;

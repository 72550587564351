import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  RedditShareButton,
  RedditIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';
import { ReactComponent as ShareIcon } from '../../assets/icons/share-icon.svg';
import useOutsideAlerter from '../../hooks/useOutsideAlerter';
import getSpacedFromDashedURL from '../../utils/getDashedURL';

function ShareToSocial({ isRight = false }) {
  const [show, setShow] = useState(false);

  const ref = React.useRef(null);

  const { isClickedOutside } = useOutsideAlerter(ref);

  useEffect(() => {
    if (isClickedOutside) {
      setShow(false);
    }
  }, [isClickedOutside]);

  const URL = window.location.href;

  const { title } = useParams();

  return (
    <div ref={ref} className="relative">
      <button onClick={() => setShow(!show)} type="button">
        <ShareIcon />
      </button>
      {show && (
        <div className={clsx('mr-2 flex gap-2 absolute top-0 animate-fade-in', isRight ? 'left-6' : 'right-6')}>
          <EmailShareButton url={`${getSpacedFromDashedURL(title)} \n\n${URL}`}>
            <EmailIcon size={32} round />
          </EmailShareButton>
          <FacebookShareButton url={URL}>
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <TwitterShareButton url={`${getSpacedFromDashedURL(title)} \n\n${URL}`}>
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          <LinkedinShareButton url={URL}>
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
          <RedditShareButton url={`${getSpacedFromDashedURL(title)} \n\n${URL}`}>
            <RedditIcon size={32} round />
          </RedditShareButton>
          <TelegramShareButton url={`${getSpacedFromDashedURL(title)} \n\n${URL}`}>
            <TelegramIcon size={32} round />
          </TelegramShareButton>
          <WhatsappShareButton url={`${getSpacedFromDashedURL(title)} \n\n${URL}`}>
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
        </div>
      )}
    </div>
  );
}

export default ShareToSocial;

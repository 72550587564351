import React from 'react';
import useViewportWidth from '../../hooks/useWindow';
import Button from '../common/button/Button';
import SoulBondNFTBG from '../../assets/images/soul-bond-nft.png';
import SoulBondNFTMobile from '../../assets/images/soul-bond-nft-mobile.png';
import EpicGames from '../../assets/images/epic-games.png';
import UnreaLEngine from '../../assets/images/unreal-engine.png';
import Toast from '../common/Toast/Toast';
import useToast from '../common/Toast/useToast';

function Claim() {
  const isMobile = useViewportWidth() < 992;
  const { showToast, isVisible } = useToast();

  return (
    <>
      {isMobile && (
        <section>
          <div className="mx-[20px] sm:mx-[40px] lg:mx-[80px] mt-[60px] mb-[40px] text-center">
            <h2 className="text-24px uppercase mb-[10px] font-medium">CLAIM YOUR FREE</h2>
            <h3 className="text-40px font-bold uppercase mb-[16px] text-primary">SOUL BOUND NFT</h3>

            <p className="mb-[40px] lg:w-1/2 ">
              Claim free Soulbound NFT of your Metabeing to have an amazing and unique life experience in MetaTor.
            </p>

            <Button textSpacing="0 60px" className="mx-auto" title="Claim Now" />

            <h3 className="mt-[40px] lg:mt-[80px] uppercase mb-[24px] font-tertiary text-14px">POWERED BY</h3>

            <img loading="lazy" src={EpicGames} className="mr-[30px] w-[70px] sm:w-[100px] mb-3" alt="epic games" />
            <img loading="lazy" src={UnreaLEngine} alt="unreal engine" className="w-[140px] mb-3 sm:w-[200px]" />
          </div>
          <img loading="lazy" src={SoulBondNFTMobile} width="100%" alt="" />
        </section>
      )}
      {!isMobile && (
        <section
          className="ml-[20px] sm:ml-[40px] lg:ml-[80px] xl:ml-[140px] 1xl:ml-[180px] 2xl:ml-[220px] min-h-[720px] flex items-center bg-no-repeat bg-right bg-contain"
          style={{ backgroundImage: `url(${SoulBondNFTBG})` }}
        >
          <div>
            <h2 className="text-24px uppercase mb-[10px] font-medium">CLAIM YOUR FREE</h2>
            <h3 className="text-40px font-bold uppercase mb-[16px] text-primary">SOUL BOUND NFT</h3>

            <p className="mb-[40px] lg:w-1/2 ">
              Be in the metaverse with yoru very own unique meta human. Meta being is yoru exact virtual reflection in
              metator. Start creating your meta being today!
            </p>

            <Button textSpacing="0 60px" title="Claim Now" onClick={showToast} />

            <h3 className="mt-[40px] lg:mt-[80px] uppercase mb-[24px] font-tertiary text-14px">POWERED BY</h3>

            <img loading="lazy" src={EpicGames} className="mr-[30px] w-[70px] sm:w-[100px]" alt="epic games" />
            <img loading="lazy" src={UnreaLEngine} alt="unreal engine" className="w-[140px] sm:w-[200px]" />
          </div>

          <Toast isVisible={isVisible} message="This Feature is launching soon, stay tuned." />
        </section>
      )}
    </>
  );
}

export default Claim;

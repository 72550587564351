import React from 'react';
import MetatorPartners from '../../assets/images/our-partners.png';

function Partners() {
  return (
    <section className="mx-[20px] sm:mx-[40px] lg:mx-[80px] xl:mx-[140px] 1xl:mx-[180px] 2xl:mx-[220px] py-[80px]">
      <div className="lg:flex justify-center items-center text-center lg:text-start">
        <div className="mb-20 lg:mb-0 lg:w-[480px] lg:mr-[200px]">
          <h2 className="text-24px font-bold uppercase mb-[16px]">OUR PARTNERS</h2>

          <p className="mb-[40px]">
            Meet MetaTor partners making this vision a reality to make people live smart and experience life at best.
          </p>
        </div>
        <div>
          <img loading="lazy" className="w-[350px] lg:w-[500px]" src={MetatorPartners} alt="partner" />
        </div>
      </div>
    </section>
  );
}

export default Partners;

import React from 'react';
import EXPERIENCE_ATTRIBUTES from '../../data/experiences.json';
import Video from '../common/Video';
// # components
import Button from '../common/button/Button';
import { getAssetsDynamically } from '../../utils/getAssetsDynamically';
import Toast from '../common/Toast/Toast';
import useToast from '../common/Toast/useToast';

function Header({ type }) {
  const { color, description, headerVideo } = EXPERIENCE_ATTRIBUTES[type];

  const { showToast, isVisible } = useToast();

  return (
    <section className="relative px-0 h-[450px] sm:h-[500px] md:h-[600px] xl:h-[750px] overflow-hidden">
      <div className="flex items-end justify-center w-full h-full text-center absolute z-10 pb-[60px] xl:pb-[100px] gradient-from-bottom-50">
        <div className="text-center pb-4 px-[10px] sm:px-[40px]">
          <h1 className="uppercase font-black mb-[20px]">
            <span className="block text-34px">WELCOME TO</span>
            <span className="text-40px uppercase">
              METATOR <span className={`text-${color}`}>{type}</span>
            </span>
          </h1>
          <p className="mb-[24px] lg:w-3/4 mx-auto">{description}</p>
          <Button className="mx-auto" title="Experience now" variant={color} onClick={showToast} />
        </div>
      </div>

      <div className="relative">
        <Video videSource={getAssetsDynamically(headerVideo, 'videos')} autoplay loop muted />
        <div className="absolute w-full h-full top-0 gradient-from-bottom-50" />
      </div>
      <Toast isVisible={isVisible} message="This Feature is launching soon, stay tuned." />
    </section>
  );
}

export default Header;

import React from 'react';

function Logo({ ...rest }) {
  return (
    <svg {...rest} xmlns="http://www.w3.org/2000/svg" width="184.451" height="32.017" viewBox="0 0 184.451 32.017">
      <g transform="translate(-455.067 -294.899)">
        <path
          d="M467.181,317.082h-.143L457.844,303.6v20.407h-2.777V298.768h2.884l9.193,13.771,9.195-13.771h2.884v25.237h-2.848V303.563Z"
          transform="translate(0 2.407)"
          fill="#fff"
        />
        <path
          d="M492.308,301.362H476.913v8.617h13.772v2.6H476.913v8.834h15.576v2.6H474.065V298.768h18.243Z"
          transform="translate(12.296 2.407)"
          fill="#fff"
        />
        <path
          d="M498.956,324.005h-2.848V301.4h-8.473v-2.631h19.793V301.4h-8.472Z"
          transform="translate(21.079 2.407)"
          fill="#fff"
        />
        <path
          d="M524.141,304.9H516.57v-6.128h22.137V304.9h-7.572v19.109h-6.994Z"
          transform="translate(39.806 2.407)"
          fill="#fff"
        />
        <path
          d="M544.9,324.708a14.023,14.023,0,0,1-5.408-1.028,13.3,13.3,0,0,1-4.289-2.794,12.9,12.9,0,0,1-2.832-4.146,12.71,12.71,0,0,1-1.026-5.083v-.072a12.855,12.855,0,0,1,3.892-9.284,13.619,13.619,0,0,1,4.327-2.812,14.74,14.74,0,0,1,10.816,0,13.328,13.328,0,0,1,4.291,2.794,12.924,12.924,0,0,1,2.83,4.146,12.683,12.683,0,0,1,1.026,5.083v.072a12.855,12.855,0,0,1-3.892,9.284,13.6,13.6,0,0,1-4.327,2.812A14.018,14.018,0,0,1,544.9,324.708Zm.054-6.309a6.343,6.343,0,0,0,2.639-.54,6.069,6.069,0,0,0,2.024-1.461,6.761,6.761,0,0,0,1.3-2.145,7.158,7.158,0,0,0,.471-2.6v-.072a7.165,7.165,0,0,0-.471-2.6,6.7,6.7,0,0,0-1.336-2.163,6.615,6.615,0,0,0-2.061-1.5,6.555,6.555,0,0,0-5.261-.018,6.285,6.285,0,0,0-3.326,3.6,7.448,7.448,0,0,0-.453,2.6v.072a7.192,7.192,0,0,0,.469,2.6,6.844,6.844,0,0,0,1.321,2.163,6.323,6.323,0,0,0,2.043,1.5A6.121,6.121,0,0,0,544.953,318.4Z"
          transform="translate(49.368 2.208)"
          fill="#fff"
        />
        <path
          d="M550.057,298.768h11.934a14.83,14.83,0,0,1,4.94.72,8.473,8.473,0,0,1,3.279,2.02,7.8,7.8,0,0,1,2.235,5.877v.071a7.936,7.936,0,0,1-1.407,4.831,8.87,8.87,0,0,1-3.785,2.92l6.022,8.8H565.2l-5.085-7.645h-3.064v7.645h-6.994Zm11.61,12.114a4.219,4.219,0,0,0,2.776-.812,2.69,2.69,0,0,0,.973-2.181v-.072a2.609,2.609,0,0,0-1.01-2.253,4.613,4.613,0,0,0-2.776-.738h-4.579v6.057Z"
          transform="translate(61.479 2.407)"
          fill="#fff"
        />
        <g transform="translate(632.53 295.049)">
          <circle cx="3.419" cy="3.419" r="3.419" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.3" />
          <g transform="translate(1.786 1.444)">
            <path
              d="M566.606,299.876l-1.146-1.535h-1.128v1.535h-.445v-3.95h1.7a1.889,1.889,0,0,1,.59.086,1.309,1.309,0,0,1,.448.239,1.076,1.076,0,0,1,.288.372,1.131,1.131,0,0,1,.1.483,1.161,1.161,0,0,1-.079.443,1.009,1.009,0,0,1-.222.339,1.255,1.255,0,0,1-.341.241,1.578,1.578,0,0,1-.43.135l1.214,1.613Zm-.306-3.331a1.183,1.183,0,0,0-.746-.208h-1.222v1.608h1.217a1.4,1.4,0,0,0,.407-.056,1.023,1.023,0,0,0,.323-.161.743.743,0,0,0,.211-.257.763.763,0,0,0,.077-.346A.69.69,0,0,0,566.3,296.545Z"
              transform="translate(-563.886 -295.926)"
              fill="#fff"
            />
          </g>
        </g>
        <path
          d="M508.515,310.716h.013l3.9-8.7,3.877,8.7h0l5.99,13.36h3l-11.5-25.417H511.13l-11.5,25.417h2.9Z"
          transform="translate(28.842 2.336)"
          fill="var(--color-primary)"
        />
      </g>
    </svg>
  );
}

export default Logo;

import React, { useState } from 'react';
import useToast from '../common/Toast/useToast';
import Toast from '../common/Toast/Toast';
import MetatorBeings from '../../assets/videos/meta-beings.mp4';
import Video from '../common/Video';
import Button from '../common/button/Button';
import EpicGames from '../../assets/images/epic-games.png';
import UnreaLEngine from '../../assets/images/unreal-engine.png';

function MetaBeings() {
  const { showToast, isVisible } = useToast();

  return (
    <section className="min-h-[500px] lg:min-h-[600px] 1xl:min-h-[800px] lg:flex items-center relative overflow-hidden m-screen">
      <div className="w-full flex justify-center lg:justify-end mb-5">
        <Video
          className="h-auto lg:h-[450px] 1xl:h-[600px] w-full lg:w-[650px] xl:w-[800px] 1xl:w-[1100px]"
          autoplay
          loop
          muted
          videSource={MetatorBeings}
        />
      </div>
      <div className="lg:absolute mx-[20px] sm:mx-[40px] lg:mx-[80px] xl:mx-[140px] 1xl:mx-[180px] text-center lg:text-start">
        <h2 className="tex-24px uppercase mb-[10px] font-medium">SOUL BOUND</h2>
        <h3 className="text-40px font-bold uppercase mb-[16px]">META BEINGS</h3>

        <p className="mb-[40px] lg:w-3/4">
          Why avatar? Be Yourself. Get your unique Meta Being powered by SoulBound NFTs.
        </p>

        <Button title="Explore Experience" className="mx-auto lg:mx-0" onClick={showToast} />

        <h3 className="mt-[80px] uppercase mb-[24px] font-tertiary text-14px">POWERED BY</h3>

        <img loading="lazy" src={EpicGames} className="mr-[30px] w-[70px] sm:w-[100px]" alt="epic games" />
        <img loading="lazy" src={UnreaLEngine} alt="unreal engine" className="w-[140px] sm:w-[200px]" />
      </div>

      <Toast isVisible={isVisible} message="This Feature is launching soon, stay tuned." />
    </section>
  );
}

export default MetaBeings;

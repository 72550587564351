import React from 'react';
// # assets
import StoreFeaturedBG from '../../assets/images/experiences/store-featured.png';
import ApexLegendPoster from '../../assets/images/experiences/apex-poster.png';
import GamePoster2 from '../../assets/images/experiences/game-poster-2.png';
import GamePoster3 from '../../assets/images/experiences/game-poster-3.png';
import GamePoster4 from '../../assets/images/experiences/game-poster-4.png';
import HourGlassIcon from '../../assets/icons/hour-glass.svg';
// # components
import Rating from '../common/Rating';

function Store() {
  const STORE_ITEMS = [
    {
      id: 1,
      image: ApexLegendPoster,
      rating: 4.5,
    },
    {
      id: 2,
      image: GamePoster2,
      rating: 3,
    },
    {
      id: 3,
      image: GamePoster3,
      rating: 4,
    },
    {
      id: 4,
      image: GamePoster4,
      rating: 5,
    },
    {
      id: 5,
      color: 'success',
      title: (
        <div className="text-center">
          <img loading="lazy" src={HourGlassIcon} alt="hour glass" />
          <p className="text-14px mt-2">Coming Soon</p>
        </div>
      ),
    },
  ];

  return (
    <section className="mb-[90px] lg:w-[950px] xl:w-[1200px] mx-[20px] sm:mx-[40px] lg:mx-auto">
      <div className="text-center">
        <h3 className="uppercase text-20px font-black mt-4 mb-3">MOST POPULAR IN METATOR</h3>
        <p className="lg:w-3/4 mx-auto mb-[60px]">
          Download most played games in Metator. Metator Gaming is available on Oculus, HTC Vive and Samsung VR Gear
        </p>
      </div>
      <div
        className="h-[400px] bg-secondary mx-auto bg-top bg-no-repeat bg-cover flex items-end justify-between px-4 mb-8"
        style={{ backgroundImage: `url(${StoreFeaturedBG})` }}
      >
        {/* featured */}
        <div className="w-full">
          <Rating value={5} className="[&>img]:w-[14px] sm:[&>img]:w-[19px]" />
          <div className="lg:flex justify-between items-center">
            <div>
              <h3 className="uppercase text-18px font-black mt-3 mb-3">MOST POPULAR IN METATOR</h3>
              <p className="lg:w-4/5 text-12px mb-5 lg:mb-0">
                Download most played games in Metator. Metator Gaming is available on Oculus, HTC Vive and Samsung VR
                Gear
              </p>
            </div>
            {/* <Button
              variant="success"
              title="Download Now"
              className="blur-sm"
              icon={DownloadIcon}
            /> */}
            <p className="opacity-70">Coming soon</p>
          </div>
        </div>
      </div>
      {/* cards */}
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-x-3">
        {STORE_ITEMS.map(({ id, image, rating, color, title }) =>
          image ? (
            <div
              key={id}
              className="h-[220px] sm:h-[310px] cursor-pointer flex items-end justify-start pl-[12px] sm:pl-[30px] pb-[12px] sm:pb-[18px] bg-center bg-cover bg-no-repeat blur-md"
              style={{ backgroundImage: `url(${image})` }}
            >
              <Rating value={rating} className="[&>img]:w-[14px] sm:[&>img]:w-[19px]" />
            </div>
          ) : (
            <div
              key={id}
              className="h-[220px] sm:h-[310px] flex items-center justify-center px-[4px] sm:px-[24px]"
              style={{ backgroundColor: `var(--color-${color})` }}
            >
              {title}
            </div>
          )
        )}
      </div>
    </section>
  );
}

export default Store;

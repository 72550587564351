import React, { useState } from 'react';
import Input from './Input/Input';
import Button from './button/Button';
import sendIconWhite from '../../assets/icons/send-icon-white.png';
import useToast from './Toast/useToast';
import Toast from './Toast/Toast';

function SubscribeToNewsletter() {
  const [loading, setLoading] = useState(false);

  const { showToast, isVisible } = useToast();
  const { showToast: showErrorToast, isVisible: isVisibleError } = useToast();

  const handleSubmit = event => {
    event.preventDefault();

    const { value } = event.target[0];

    if (value && !loading) {
      setLoading(true);
      fetch('https://sheet.best/api/sheets/83d3a12a-a4a9-4817-b88d-6bd4e8aad3f6', {
        method: 'POST',
        body: JSON.stringify([{ email: value }]),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(() => {
          showToast();
          setLoading(false);
        })
        .catch(() => {
          showErrorToast();
          setLoading(false);
        });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} name="subscribe-newsletter">
        <div className="flex items-center flex-col lg:flex-row justify-start">
          <Input type="email" id="email" name="email" className="w-[100%] lg:w-[350px] mb-[16px] lg:mb-0" required />
          <Button
            htmlType="submit"
            title="Subscribe"
            icon={sendIconWhite}
            className="lg:ml-2 w-3/4 sm:w-3/6 lg:w-[200px]"
            loading={loading}
          />
        </div>
      </form>
      <Toast isVisible={isVisible} variant="success" message="Thank you for subscribing!" />
      <Toast isVisible={isVisibleError} variant="danger" message="Something went wrong please try again later!" />
    </>
  );
}

export default SubscribeToNewsletter;

import React from 'react';

function MetaTvLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="316.516" height="68.264" viewBox="0 0 316.516 68.264">
      <g transform="translate(-131.439 -344.312)">
        <g transform="translate(131.439 369.135)">
          <path
            d="M147.585,416.005h-.192l-12.254-17.972v27.2h-3.7V391.594h3.845l12.253,18.357,12.254-18.357h3.844v33.637h-3.8V397.985Z"
            transform="translate(-131.439 -383.431)"
            fill="#fff"
          />
          <path
            d="M215.54,395.054H195.022v11.485h18.356V410H195.022v11.773h20.759v3.46H191.226V391.594H215.54Z"
            transform="translate(-149.516 -383.431)"
            fill="#fff"
          />
          <path
            d="M249.019,425.231h-3.8V395.1H233.931v-3.508h26.381V395.1H249.019Z"
            transform="translate(-162.428 -383.431)"
            fill="#fff"
          />
          <path
            d="M335.079,399.763H324.987v-8.169h29.5v8.169H344.4v25.468h-9.323Z"
            transform="translate(-189.959 -383.431)"
            fill="#fff"
          />
          <path
            d="M389.549,425.613a18.7,18.7,0,0,1-7.208-1.369,17.741,17.741,0,0,1-5.718-3.724,17.181,17.181,0,0,1-3.773-5.527,16.9,16.9,0,0,1-1.369-6.775v-.1a17.14,17.14,0,0,1,5.189-12.374A18.108,18.108,0,0,1,382.437,392a19.653,19.653,0,0,1,14.415,0,17.747,17.747,0,0,1,5.718,3.724,17.19,17.19,0,0,1,3.773,5.526,16.9,16.9,0,0,1,1.369,6.776v.1a17.14,17.14,0,0,1-5.189,12.374,18.125,18.125,0,0,1-5.767,3.748A18.7,18.7,0,0,1,389.549,425.613Zm.072-8.41a8.432,8.432,0,0,0,3.518-.72,8.09,8.09,0,0,0,2.7-1.946,9.033,9.033,0,0,0,1.734-2.859,9.571,9.571,0,0,0,.627-3.46v-.1a9.58,9.58,0,0,0-.627-3.46,8.891,8.891,0,0,0-1.783-2.883,8.784,8.784,0,0,0-2.747-1.995,8.753,8.753,0,0,0-7.011-.024,8.373,8.373,0,0,0-4.434,4.805,9.913,9.913,0,0,0-.6,3.46v.1a9.58,9.58,0,0,0,.626,3.459,9.078,9.078,0,0,0,1.76,2.884,8.455,8.455,0,0,0,2.722,1.994A8.184,8.184,0,0,0,389.621,417.2Z"
            transform="translate(-204.016 -383.139)"
            fill="#fff"
          />
          <path
            d="M430.37,391.594h15.906a19.7,19.7,0,0,1,6.584.961,11.28,11.28,0,0,1,4.373,2.691,10.4,10.4,0,0,1,2.979,7.833v.1a10.578,10.578,0,0,1-1.874,6.439,11.844,11.844,0,0,1-5.045,3.892l8.025,11.725H450.552l-6.775-10.188h-4.085v10.188H430.37Zm15.474,16.146a5.632,5.632,0,0,0,3.7-1.081,3.589,3.589,0,0,0,1.3-2.908v-.1a3.477,3.477,0,0,0-1.346-3,6.15,6.15,0,0,0-3.7-.985h-6.1v8.073Z"
            transform="translate(-221.822 -383.431)"
            fill="#fff"
          />
          <g transform="translate(236.53)">
            <circle
              cx="4.557"
              cy="4.557"
              r="4.557"
              fill="none"
              stroke="#fff"
              strokeMiterlimit="10"
              strokeWidth="0.945"
            />
            <g transform="translate(2.38 1.925)">
              <path
                d="M477.514,387.916l-1.526-2.045h-1.5v2.045h-.594v-5.264h2.264a2.544,2.544,0,0,1,.786.113,1.769,1.769,0,0,1,.6.32,1.424,1.424,0,0,1,.384.5,1.5,1.5,0,0,1,.135.643,1.556,1.556,0,0,1-.105.591,1.342,1.342,0,0,1-.3.451,1.658,1.658,0,0,1-.455.32,2.082,2.082,0,0,1-.571.181l1.617,2.149Zm-.407-4.44a1.587,1.587,0,0,0-.995-.276h-1.628v2.143H476.1a1.867,1.867,0,0,0,.543-.075,1.33,1.33,0,0,0,.43-.215.994.994,0,0,0,.283-.344,1.016,1.016,0,0,0,.1-.46A.923.923,0,0,0,477.107,383.477Z"
                transform="translate(-473.889 -382.652)"
                fill="#fff"
              />
            </g>
          </g>
          <path
            d="M283.518,407.32h.017l5.2-11.6,5.167,11.6h0l7.983,17.807h4L290.561,391.25H287l-15.329,33.877h3.859Z"
            transform="translate(-173.84 -383.327)"
            fill="var(--color-primary)"
          />
        </g>
        <g transform="translate(386.141 344.311)">
          <rect width="61.813" height="38.137" rx="6.718" transform="translate(0 30.126)" fill="var(--color-primary)" />
          <path
            d="M528.684,375.041a1.275,1.275,0,0,1-.9-.374l-15.843-15.843a1.276,1.276,0,0,1,1.8-1.8l14.941,14.941,27.275-27.275a1.276,1.276,0,0,1,1.8,1.8l-28.178,28.177A1.272,1.272,0,0,1,528.684,375.041Z"
            transform="translate(-501.073 -344.311)"
            fill="#fff"
          />
          <path
            d="M532.833,400.96l11.5,8.751a1.435,1.435,0,0,1-.1,2.35l-11.5,7.35a1.435,1.435,0,0,1-2.207-1.209V402.1A1.435,1.435,0,0,1,532.833,400.96Z"
            transform="translate(-506.807 -361.35)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}

export default MetaTvLogo;

import React, { useEffect, useRef } from 'react';

function Video({ poster = '', videSource = '', loop = false, autoplay = false, muted = true, ...rest }) {
  const videoRef = useRef(null);

  useEffect(() => {
    videoRef.current.load();
  }, [videSource]);

  return (
    <video ref={videoRef} width="100%" poster={poster} {...rest} autoPlay={autoplay} muted={muted} loop={loop}>
      <track kind="captions" />
      <source src={videSource} type="video/mp4" />
    </video>
  );
}

export default Video;

import React from 'react';

function TeamTrigger({ name }) {
  const teamNames = {
    core: 'Core Team',
    design: 'Design Team',
    development: 'Development Team',
    blockchain: 'Blockchain Team',
    marketing: 'Marketing Team',
  };

  return (
    <div className="mb-[40px]">
      {' '}
      <span className="inline-block w-[13px] h-[13px] mr-3 border-2 border-gray" /> {teamNames[name] || ''}{' '}
    </div>
  );
}

export default TeamTrigger;

import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useViewportWidth from '../../../hooks/useWindow';

// # styles
import './tabs.css';

function Tabs({
  items = [],
  defaultCurrent = 0,
  defaultCurrentMobile = 0,
  onChange = () => {},
  className = '',
  contentClassName = '',
  centered = false,
  ...rest
}) {
  const [isMobileViewport, setIsMobileViewport] = useState(false);

  // removing the items that do not have a key
  const tabs = React.useMemo(
    () => (items || []).filter(item => item && typeof item === 'object' && 'key' in item),
    [items]
  );

  const [activeKey, setActiveKey] = useState(defaultCurrent || (tabs.length > 0 ? tabs[0].key : null));

  // return active tab content by activeKey
  const getCurrentKeyContent = useCallback(() => {
    const current = tabs.find(item => item.key === activeKey);
    return current ? current.content : null;
  }, [activeKey, isMobileViewport]);

  // setting active key internally and calling onChange callback
  const handleTabClick = useCallback(
    key => {
      setActiveKey(key);
      onChange(key);
    },
    [onChange]
  );

  const isMobile = useViewportWidth() < 768;

  useEffect(() => {
    setIsMobileViewport(isMobile);
    if (isMobile) {
      setActiveKey(defaultCurrentMobile || defaultCurrent);
    }
  }, [isMobile]);

  return (
    <div className={clsx('tabs-container', className)} {...rest}>
      <ul className={clsx('tabs gap-y-8', centered && 'justify-center')}>
        {tabs.map(
          ({ label, key, hideOnMobile, extraContent }) =>
            (!hideOnMobile || !isMobileViewport) && (
              <div key={key} className="relative">
                <li className={clsx('tab-item', activeKey === key && 'tab-item--active')}>
                  <button className="tab-item__btn" type="button" onClick={() => handleTabClick(key)}>
                    {label}
                  </button>
                </li>
                {extraContent}
              </div>
            )
        )}
      </ul>

      {/* content */}
      <div className={clsx('tab-item__content', contentClassName)}>{getCurrentKeyContent()}</div>
    </div>
  );
}

export default Tabs;

Tabs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
    })
  ),
  defaultCurrent: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
};

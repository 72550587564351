import React, { useEffect, useState } from 'react';
// # assets
import AllTeam from '../../assets/images/meta-team/All.png';
// # components
import useViewportWidth from '../../hooks/useWindow';
import TeamSlider from './TeamSlider';
// # assets
import CoreTeam1 from '../../assets/images/meta-team/core-team-1.png';
import CoreTeam2 from '../../assets/images/meta-team/core-team-2.png';
import CoreTeam3 from '../../assets/images/meta-team/core-team-3.png';
import CoreTeam4 from '../../assets/images/meta-team/core-team-4.png';
import CoreTeam5 from '../../assets/images/meta-team/core-team-5.png';
import CoreTeam6 from '../../assets/images/meta-team/core-team-6.png';
import CoreTeam7 from '../../assets/images/meta-team/core-team-7.png';
import DesignTeam1 from '../../assets/images/meta-team/design-team-1.png';
import DesignTeam2 from '../../assets/images/meta-team/design-team-2.png';
import DesignTeam3 from '../../assets/images/meta-team/design-team-3.png';
import DesignTeam4 from '../../assets/images/meta-team/design-team-4.png';
import DesignTeam5 from '../../assets/images/meta-team/design-team-5.png';
import DesignTeam6 from '../../assets/images/meta-team/design-team-6.png';
import DesignTeam7 from '../../assets/images/meta-team/design-team-7.png';
import All1 from '../../assets/images/meta-team/1.png';
import All2 from '../../assets/images/meta-team/2.png';
import All3 from '../../assets/images/meta-team/3.png';
import All4 from '../../assets/images/meta-team/4.png';
import All5 from '../../assets/images/meta-team/5.png';
import All6 from '../../assets/images/meta-team/6.png';
import All7 from '../../assets/images/meta-team/7.png';
import All8 from '../../assets/images/meta-team/8.png';
import All9 from '../../assets/images/meta-team/9.png';
import All10 from '../../assets/images/meta-team/10.png';
import All11 from '../../assets/images/meta-team/11.png';
import All12 from '../../assets/images/meta-team/12.png';

const TeamSliderData = {
  all: [
    {
      image: CoreTeam1,
      key: 'rao',
      name: 'Rao Naveed',
    },
    {
      image: CoreTeam2,
      key: 'CoreTeam2',
      name: 'CoreTeam2',
    },
    {
      image: CoreTeam3,
      key: 'CoreTeam3',
      name: 'CoreTeam3',
    },
    {
      image: CoreTeam4,
      key: 'CoreTeam4',
      name: 'CoreTeam4',
    },
    {
      image: CoreTeam5,
      key: 'CoreTeam5',
      name: 'CoreTeam5',
    },
    {
      image: CoreTeam6,
      key: 'CoreTeam6',
      name: 'CoreTeam6',
    },
    {
      image: CoreTeam7,
      key: 'CoreTeam7',
      name: 'CoreTeam7',
    },
    {
      image: DesignTeam1,
      key: 'DesignTeam1',
      name: 'DesignTeam1',
    },
    {
      image: DesignTeam2,
      key: 'DesignTeam2',
      name: 'DesignTeam2',
    },
    {
      image: DesignTeam3,
      key: 'DesignTeam3',
      name: 'DesignTeam3',
    },
    {
      image: DesignTeam4,
      key: 'DesignTeam4',
      name: 'DesignTeam4',
    },
    {
      image: DesignTeam5,
      key: 'DesignTeam5',
      name: 'DesignTeam5',
    },
    {
      image: DesignTeam6,
      key: 'DesignTeam6',
      name: 'DesignTeam6',
    },
    {
      image: CoreTeam2,
      key: 'DesignTeam7',
      name: 'DesignTeam7',
    },
    {
      image: CoreTeam3,
      key: 'DesignTeam8',
      name: 'DesignTeam8',
    },
    {
      image: CoreTeam6,
      key: 'DesignTeam9',
      name: 'DesignTeam9',
    },
    {
      image: DesignTeam7,
      key: 'DesignTeam10',
      name: 'DesignTeam10',
    },
    {
      image: All1,
      key: 'All1',
      name: 'All1',
    },
    {
      image: All2,
      key: 'All2',
      name: 'All2',
    },
    {
      image: All3,
      key: 'All3',
      name: 'All3',
    },
    {
      image: All4,
      key: 'All14',
      name: 'All14',
    },
    {
      image: All5,
      key: 'All5',
      name: 'All5',
    },
    {
      image: All6,
      key: 'All6',
      name: 'All6',
    },
    {
      image: All7,
      key: 'All7',
      name: 'All7',
    },
    {
      image: All8,
      key: 'All8',
      name: 'All8',
    },
    {
      image: All9,
      key: 'All9',
      name: 'All9',
    },
    {
      image: All10,
      key: 'All10',
      name: 'All10',
    },
    {
      image: All12,
      key: 'All12',
      name: 'All12',
    },
    {
      image: All11,
      key: 'All11',
      name: 'All11',
    },
  ],
  core: [],
  design: [],
};

function Teams() {
  const [isMobileViewport, setIsMobileViewport] = useState(false);

  const isMobile = useViewportWidth() < 768;

  useEffect(() => {
    setIsMobileViewport(isMobile);
  }, [isMobile]);

  return (
    <section className="mt-[60px] lg:mt-[120px] mx-[20px] lg:mx-0">
      <div className="text-center pb-[50px]">
        <h2 className="text-24px font-bold mb-[18px]">TEAM</h2>
        <p className="lg:w-3/4 mx-auto">
          Meet team MetaTor, a passionate group of talented and qualified people committed to making your life easy and
          smart.
        </p>
      </div>
      {isMobileViewport ? (
        <TeamSlider data={TeamSliderData.all} />
      ) : (
        <div className="px-10 text-center">
          <img loading="lazy" className="w-[1800px]" src={AllTeam} alt="Metator team" />
        </div>
      )}
    </section>
  );
}

export default Teams;

import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { css } from '@emotion/css';
import SlickArrow from './SlickArrow';
import { ReactComponent as ArrowNextBoxed } from '../../assets/icons/arrow-right-boxed.svg';
// # styles

function PrimarySlickSlider({
  children,
  color,
  settings,
  slides = 3,
  mobileSlides = 2,
  smallMobileSlides = 1,
  autoplay = false,
  autoplaySpeed = 3000,
  ...rest
}) {
  const colorVar = `var(--color-${color})`;

  const arbitraryStyles = css`
    border-color: ${colorVar};
    &:hover {
      background-color: ${colorVar};
    }
  `;

  // configs for slick slider
  const customSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: slides,
    slidesToScroll: slides,
    nextArrow: (
      <SlickArrow
        direction="next"
        icons={{
          next: (
            <div className={`border w-max ${arbitraryStyles}`}>
              <ArrowNextBoxed />
            </div>
          ),
        }}
      />
    ),
    ...settings,
  };

  return (
    <div>
      <Slider
        {...rest}
        {...customSettings}
        autoplay={autoplay}
        autoplaySpeed={autoplaySpeed}
        responsive={[
          {
            breakpoint: 1280,
            settings: { slidesToShow: mobileSlides, slidesToScroll: mobileSlides, initialSlide: 1 },
          },
          {
            breakpoint: 641,
            settings: {
              slidesToShow: smallMobileSlides,
              slidesToScroll: smallMobileSlides,
              initialSlide: 1,
            },
          },
        ]}
      >
        {children}
      </Slider>
    </div>
  );
}

export default PrimarySlickSlider;

PrimarySlickSlider.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  slides: PropTypes.number,
};

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import BackToTop from '../components/common/BackToTop';
import useScrollToTop from '../hooks/useScrollToTop';
// # components
import Home from '../pages/Home';
import PasswordProtected from '../pages/PasswordProtected';
import Experiences from '../pages/Experiences';
import Experience from '../pages/Experience';
import AboutUs from '../pages/AboutUs';
import Builder from '../pages/Builder';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TermsAndConditions from '../pages/TermsAndConditions';
import CaseStudy from '../pages/CaseStudy';
import CaseStudies from '../pages/CaseStudies';
import ProtectedRoute from './ProtectedRoute';

function AppRouter() {
  const routes = [
    {
      path: '/',
      element: <Home />,
      key: 'home',
    },
    {
      path: '/experiences',
      element: <Experiences />,
      key: 'experiences',
    },
    {
      path: '/experiences/:type',
      element: <Experience />,
      key: 'experience',
    },
    {
      path: '/about-us',
      element: <AboutUs />,
      key: 'about-us',
    },
    {
      path: '/builder',
      element: <Builder />,
      key: 'builder',
    },
    {
      path: '/privacy-policy',
      element: <PrivacyPolicy />,
      key: 'privacy-policy',
    },
    {
      path: '/terms-and-conditions',
      element: <TermsAndConditions />,
      key: 'terms-and-conditions',
    },
    {
      path: '/case-studies',
      element: <CaseStudies />,
      key: 'case-studies',
    },
    {
      path: '/case-studies/:id/:title',
      element: <CaseStudy />,
      key: 'case-studies',
    },
  ];

  // This is a custom hook that will scroll to the top of the page when the route changes.
  useScrollToTop();

  return (
    <main>
      <Routes>
        {routes.map(({ path, element, key }) => (
          <Route key={key} path={path} element={<ProtectedRoute element={element} />} />
        ))}
        <Route path="/password-protected" element={<PasswordProtected />} />
      </Routes>

      <BackToTop />
    </main>
  );
}

export default AppRouter;

import React from 'react';
// # assets
import { ReactComponent as IsbIcon } from '../../assets/icons/islamabad.svg';
import { ReactComponent as EstoniaIcon } from '../../assets/icons/estonia.svg';
import { ReactComponent as IstanbulIcon } from '../../assets/icons/istanbul.svg';
import { ReactComponent as DubaiIcon } from '../../assets/icons/dubai.svg';

function Offices() {
  const OFFICES = [
    {
      name: 'ISLAMABAD OFFICE',
      description: 'Office 10, 3rd Floor, Al Rehmat Plaza, G11 Markaz, Islamabad, Pakistan.',
      icon: <IsbIcon className="path:fill-primary" />,
      key: 'islamabad',
    },
    {
      name: 'DUBAI OFFICE',
      description: 'Dubai, UAE',
      icon: <DubaiIcon className="path:fill-primary" />,
      key: 'dubai',
    },
    {
      name: 'ESTONIA OFFICE',
      description: 'Pärnu maakond, Pärnu linn, Pärnu linn, Papiniidu tn 5, 80042',
      icon: <EstoniaIcon className="path:fill-primary" />,
      key: 'estonia',
    },
    {
      name: 'ISTANBUL OFFICE',
      description: 'Cumhuriyet, İncirli Dede Cd. floor 41 Şişli/İstanbul, Turkey',
      icon: <IstanbulIcon className="path:fill-primary" />,
      key: 'istanbul',
    },
  ];

  return (
    <section className="mx-[40px] lg:mx-[100px] xl:mx-[160px] text-center pb-[80px] lg:pb-[200px] mt-[80px]">
      <h2 className="text-24px font-bold uppercase mb-[60px]">OUR OFFICES</h2>

      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 mt-[20px] lg:mt-[60px] gap-x-[180px] xl:gap-x-[60px] gap-y-[60px]">
        {OFFICES.map(({ name, key, description, icon }) => (
          <div key={key}>
            <div className="w-[120px] mx-auto flex items-end justify-center sm:h-[120px]">{icon}</div>
            <h3 className="mt-[38px] mb-[16px] lg:mb-[30px] font-bold text-1">{name}</h3>
            <p className="text-12px">{description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Offices;

import React from 'react';
// # assets
import MetatorWorldPoster from '../assets/images/posters/metator-world.png';
import MetatorShoppingPoster from '../assets/images/posters/metator-shopping.png';
import MetatorWeddingPoster from '../assets/images/posters/metator-wedding.png';
import MetatorMeetingPoster from '../assets/images/posters/metator-meeting.png';
import MetatorTherapyPoster from '../assets/images/posters/metator-therapy.png';
import MetatorFashionPoster from '../assets/images/posters/metator-fashion.png';
import MetatorCasinoPoster from '../assets/images/posters/metator-casino.png';
import MetatorHomePoster from '../assets/images/posters/metator-home.png';
import WelcomeMetator from '../assets/videos/metator-welcome.mp4';
import MetatorShopping from '../assets/videos/metator-shopping.mp4';
import MetatorWedding from '../assets/videos/metator-wedding.mp4';
import MetatorMeeting from '../assets/videos/metator-meeting.mp4';
import MetatorTherapy from '../assets/videos/metator-therapy.mp4';
import MetatorFashionV2 from '../assets/videos/metator-fashion-v2.mp4';
import MetatorCasino from '../assets/videos/metator-casino.mp4';
import MetatorHome from '../assets/videos/metator-home.mp4';
// # components
import Teams from '../components/home/Teams';
import Offices from '../components/home/Offices';
import StepDivider from '../components/common/StepDivider';
import Media from '../components/home/Media';
import SocialLinks from '../components/common/SocialLinks';
import MetaTalk from '../components/home/MetaTalk';
import HeroSection from '../components/home/HeroSection';
import Claim from '../components/home/Claim';
import Partners from '../components/home/Partners';
import VirtualAssistant from '../components/home/VirtualAssistant';
import LivingDead from '../components/home/LivingDead';
import MetaBeings from '../components/home/MetaBeings';
import VideoPlayer from '../components/common/VideoPlayer';
import JudicialSystem from '../components/home/JudicialSystem';
import MetaTags from '../components/common/MetaTags';
import Featured from '../components/home/Featured';

function Home() {
  return (
    <div className="bg-black">
      <MetaTags page="home" />

      <SocialLinks className="flex justify-center mb-2 xlg:hidden pt-3" />

      {/* Hero section */}
      <HeroSection />

      {/* video trailers */}
      <section id="explore">
        <StepDivider />
        <div className="lg:w-3/4 mx-[20px] sm:mx-[40px] lg:mx-auto py-[60px] text-center">
          <h2 className="text-20px uppercase font-semibold mb-[16px]">
            Think beyond <br />
            <span className="text-40px font-black">
              be <span className="text-primary">involved</span>
            </span>
          </h2>
          <p className="lg:w-3/4 mx-auto">
            MetaTor is where talent is nourished to create more opportunities and make life better.
          </p>
        </div>

        <div className="block xl:grid grid-cols-2 gap-y-10">
          <VideoPlayer
            videSource={WelcomeMetator}
            description="Presenting you MetaTor experiences, live every moment, and embrace the technology for a smart future."
            primaryTitle={
              <div className="text-40px">
                METATOR <span className="text-primary">WORLDS</span>
              </div>
            }
            poster={MetatorWorldPoster}
            className="gradient-from-bottom"
          />
          <VideoPlayer
            videSource={MetatorShopping}
            description="Enjoy an ultimate and interactive shopping experience to get your goods delivered to your doorstep."
            primaryTitle={
              <div className="text-40px">
                METATOR <span className="text-primary">Shopping</span>
              </div>
            }
            poster={MetatorShoppingPoster}
            className="gradient-from-bottom"
            wrapperClassName="my-10 xl:my-0"
          />
          <div className="col-span-2">
            <VideoPlayer
              videSource={MetatorCasino}
              description="Experience this new world of opportunities and wonders to make your life smart and easy."
              primaryTitle="METATOR Experience"
              poster={MetatorCasinoPoster}
            />
          </div>
        </div>
      </section>

      <MetaBeings />

      <MetaTalk />

      <VirtualAssistant />

      <LivingDead />

      <JudicialSystem />

      {/* video trailers */}
      <section className="block xl:grid grid-cols-2 gap-y-10">
        <VideoPlayer
          description="Presenting you MetaTor experiences, live every moment, and embrace the technology for a smart future."
          primaryTitle="METATOR EXPERIENCES"
          poster={MetatorHomePoster}
          videSource={MetatorHome}
          className="gradient-from-bottom"
        />
        <VideoPlayer
          description="Enter into the world of mixed reality where opportunities are created everyday in every city and country of MetaTor."
          primaryTitle="METATOR STATES"
          poster={MetatorWorldPoster}
          videSource={WelcomeMetator}
          className="gradient-from-bottom"
          wrapperClassName="my-10 xl:my-0"
        />
        <div className="col-span-2">
          <VideoPlayer
            description="Love recognizes no barriers and getting married has become more expressive and engaging than ever in MetaTor."
            primaryTitle={
              <div>
                <span className="block">METATOR EXPERIENCES</span>
                <span className="text-40px">
                  Get <span className="text-primary">Married</span> in metator
                </span>
              </div>
            }
            poster={MetatorWeddingPoster}
            videSource={MetatorWedding}
          />
        </div>
      </section>

      {/* metator media */}

      <Media />

      {/* Media Coverage */}

      <Featured />

      {/* video trailers */}
      <section className="block xl:grid grid-cols-2 mt-[140px] gap-y-10 mb-8">
        <VideoPlayer
          description="Make your meetings more productive and connected to keep professional workflow aligned and meet business goals efficiently."
          primaryTitle="METATOR MEETINGS"
          poster={MetatorMeetingPoster}
          videSource={MetatorMeeting}
          className="gradient-from-bottom"
        />
        <VideoPlayer
          description="Your mental health matters more than anything. Get yourself a one-on-one therapy session with qualified doctors in MetaTor."
          primaryTitle="METATOR THERAPY"
          poster={MetatorTherapyPoster}
          videSource={MetatorTherapy}
          className="gradient-from-bottom"
          wrapperClassName="my-10 xl:my-0"
        />
        <div className="col-span-2">
          <VideoPlayer
            description="Revolutionizing the fashion industry, providing a convenient approach to artists, models, and designers to grow in MetaTor."
            primaryTitle={
              <div>
                <span className="block">METATOR EXPERIENCES</span>
                <span className="text-40px">
                  METATOR <span className="text-primary">FASHION</span>
                </span>
              </div>
            }
            poster={MetatorFashionPoster}
            videSource={MetatorFashionV2}
          />
        </div>
      </section>

      {/* Claim section */}
      <Claim />

      <Partners />

      {/* team section */}
      <Teams />

      {/* our offices */}
      <Offices />
    </div>
  );
}

export default Home;

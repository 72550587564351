import React, { useMemo } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { ReactComponent as TwIcon } from '../../assets/icons/tw-icon.svg';
import { ReactComponent as InstaIcon } from '../../assets/icons/insta-icon.svg';
import { ReactComponent as MedIcon } from '../../assets/icons/med-icon.svg';
import { ReactComponent as YoutubeIcon } from '../../assets/icons/yt-icon.svg';
import { ReactComponent as InIcon } from '../../assets/icons/ln-icon-gray.svg';

function SocialLinks({ className = '', filterOut = false, ...rest }) {
  const socials = [
    {
      Icon: TwIcon,
      path: 'https://twitter.com/MetaTorOfficial',
      key: 'tw',
      alt: 'twitter social icon',
    },
    {
      Icon: InIcon,
      path: 'https://www.linkedin.com/company/metator-official/',
      key: 'In',
      alt: 'linkedin social icon',
    },
    {
      Icon: MedIcon,
      path: 'https://medium.com/@metator',
      key: 'med',
      alt: 'medium social icon',
    },
    {
      Icon: YoutubeIcon,
      path: 'https://www.youtube.com/@MetaTor',
      key: 'youtube',
      alt: 'youtube social icon',
    },
    {
      Icon: InstaIcon,
      path: 'https://instagram.com/metatorofficial',
      key: 'insta',
      alt: 'instagram social icon',
    },
  ];

  const socialsData = useMemo(() => {
    if (filterOut) {
      return socials;
    }

    return [...socials];
  });

  return (
    <ul className={clsx('flex items-center', className)} {...rest}>
      {socialsData.map(({ key, path, Icon }) => (
        <li key={key} className="mr-4 last:m-0">
          <a href={path} target="_blank" rel="noreferrer">
            <Icon width={20} className="svg-fill--primary hover:scale-150 transition-all duration-300" />
          </a>
        </li>
      ))}
    </ul>
  );
}

export default SocialLinks;

SocialLinks.propTypes = {
  className: PropTypes.string,
};

import React from 'react';
// # assets
import SubscribeBG from '../../assets/images/experiences/subscribe-gaming.png';
// # components
import Button from '../common/button/Button';
import Input from '../common/Input/Input';
import SubscribeToNewsletter from '../common/SubscribeToNewsletter';
import Toast from '../common/Toast/Toast';
import useToast from '../common/Toast/useToast';

function Subscribe() {
  const { showToast, isVisible } = useToast();

  return (
    <section className="mb-[80px] lg:my-[190px] w-full lg:w-[950px] xl:w-[1200px] 2xl:w-[1320px] mx-auto text-center lg:text-start">
      <div
        className="px-[20px] lg:px-[50px] flex items-center min-h-[160px] xl:min-h-[205px] bg-top  bg-no-repeat bg-cover mx-[20px] lg:mx-0"
        style={{ backgroundImage: `url(${SubscribeBG})` }}
      >
        <div className="lg:flex items-center justify-between w-full">
          <div className="mb-5 lg:mb-0">
            <h3 className="text-20px font-black text-success mb-2">Subscribe to Metator Gaming</h3>
            <p className="text-14px">Get updates about what&apos;s happening in MetaTor gaming world.</p>
          </div>
          <div className="flex justify-center lg:justify-start">
            <Input className="w-[270px] xl:w-[345px]" />
            <Button noAnimation title="Subscribe" variant="success" icon={false} onClick={showToast} />
          </div>
        </div>
      </div>
      <Toast isVisible={isVisible} message="This Feature is launching soon, stay tuned." />
    </section>
  );
}

export default Subscribe;

import React, { useMemo } from 'react';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
// # assets

// # components
import PrimaryCard from '../components/common/PrimaryCard';
import MetaTags from '../components/common/MetaTags';
import { ReactComponent as MetatorIco } from '../assets/icons/metator-ico.svg';
import ShareToSocial from '../components/common/ShareToSocial';
import Explore from '../components/case-studies/Explore';
import { getAssetsDynamically } from '../utils/getAssetsDynamically';
import BreadCrumb from '../components/case-studies/Breadcumb';
import CASE_STUDIES from '../data/case-studies.json';
import CaseStudy1 from '../components/case-studies/CaseStudy1';
import CaseStudy2 from '../components/case-studies/CaseStudy2';
import CaseStudy3 from '../components/case-studies/CaseStudy3';
import { getDashedURL } from '../utils/getDashedURL';
import CaseStudy4 from '../components/case-studies/CaseStudy4';
import CaseStudy5 from '../components/case-studies/CaseStudy5';

function CaseStudy() {
  const { id } = useParams();

  const caseStudies = useMemo(() => CASE_STUDIES.filter(item => item.key !== id && !item.type), [id]);
  const getCurrentCaseStudy = useMemo(() => CASE_STUDIES.find(item => item.key === id), [id]);

  const getCaseStudyById = useMemo(() => {
    const caseStudies = [
      { key: 1, content: <CaseStudy1 /> },
      { key: 2, content: <CaseStudy2 /> },
      { key: 3, content: <CaseStudy3 /> },
      { key: 4, content: <CaseStudy4 /> },
      { key: 5, content: <CaseStudy5 /> },
    ];
    return caseStudies.find(_ => _.key === Number(id)).content;
  });

  return (
    <div className="mb-[80px] lg:mb-[180px]">
      <MetaTags page="case-studies" />
      <BreadCrumb
        className="w-5/6 2xl:w-[1440px] mx-auto"
        title="Metator Case Studies"
        url="/case-studies"
        items={[{ key: '1', title: getCurrentCaseStudy.title }]}
      />
      <section className="w-5/6 2xl:w-[1440px] mx-auto">
        <div className="relative text-center">
          <img
            loading="lazy"
            src={getAssetsDynamically(getCurrentCaseStudy.poster, 'images/case-studies')}
            alt="featured case study"
            className="mx-auto w-full"
          />
          <div className="absolute w-full h-full top-0 gradient-from-bottom-50" />
        </div>
      </section>

      <div className="grid grid-cols-12 gap-2 w-5/6 2xl:w-[1440px] mx-auto  1xl:-mt-8 2xl:-mt-16 relative z-10 mb-[60px] lg:mb-[100px]">
        <section className="col-span-12 1xl:col-span-8">
          <div>
            <h1 className="uppercase font-bold mb-[14px] text-30px">{getCurrentCaseStudy.title}</h1>
          </div>
          <div className="flex justify-between items-center mb-2">
            <div className="flex items-center font-light text-12px">
              <div className="flex items-center gap-2">
                <MetatorIco className="[&>path]:fill-primary w-[14px] sm:w-[20px]" /> <span>MetaTor</span>
              </div>
              <span className="mx-4">|</span>

              <p>{getCurrentCaseStudy.date}</p>
            </div>
            <ShareToSocial />
          </div>
          <hr className="bg-gray opacity-50" />
          {/* content */}
          <section className="my-5">{getCaseStudyById}</section>

          <hr className="bg-gray opacity-50 mb-2" />
          <ShareToSocial isRight />
        </section>
        <section className="col-span-4 mt-20 hidden 1xl:block">
          <h2 className="text-center text-14px">MORE CASE STUDIES</h2>
          <div>
            {caseStudies.map(({ title, description, extraContent, containerClassName, poster, key, type, date }) =>
              type === 'divider' ? (
                <div className="hidden lg:block h-[120px] border border-white opacity-50" key={key} />
              ) : (
                <PrimaryCard
                  containerClassName={clsx('mb-16 lg:mb-0', containerClassName)}
                  extraContent={extraContent}
                  description={description}
                  primaryTitle={title}
                  poster={getAssetsDynamically(poster, 'images/case-studies')}
                  className="min-h-[220px] sm:min-h-[380px]"
                  posterClassName="!h-[240px]"
                  variant="secondary"
                  isSecondarySm
                  ico={MetatorIco}
                  URL={`/case-studies/${key}/${getDashedURL(title)}`}
                  key={key}
                  date={date}
                />
              )
            )}
          </div>
        </section>
      </div>
      {/* explore */}
      <Explore data={caseStudies} />
    </div>
  );
}

export default CaseStudy;

import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import EXPERIENCE_ATTRIBUTES from '../../data/experiences.json';
import { getAssetsDynamically } from '../../utils/getAssetsDynamically';
// # assets
import LoginIcon from '../../assets/images/experiences/login-icon.png';
import OculusIcon from '../../assets/images/experiences/oculus-icon.png';
import LookAroundIcon from '../../assets/images/experiences/look-around-icon.png';
// # components
import Button from '../common/button/Button';
import Toast from '../common/Toast/Toast';
import useToast from '../common/Toast/useToast';

function About({ type }) {
  const { color, aboutIcon, about, brandsImage, noBrands } = EXPERIENCE_ATTRIBUTES[type];

  const { showToast, isVisible } = useToast();

  return (
    <section className="mx-[20px] sm:mx-[40px] lg:mx-[80px] xl:mx-[120px] 2xl:mx-[280px]">
      <div className="flex flex-col sm:flex-row">
        <div className={clsx(noBrands ? 'sm:w-full' : 'sm:w-7/12')}>
          <div className="flex items-center mb-[30px]">
            <img
              loading="lazy"
              className="mr-5 w-[30px] sm:w-[50px]"
              src={getAssetsDynamically(aboutIcon, 'images/experiences')}
              alt={type}
            />
            <h2 className="font-black uppercase text-32px">
              <span className={`text-${color}`}> {type}</span> EXPERIENCE
            </h2>
          </div>
          <p className="my-10">{about}</p>

          <p className="mb-6">For best experience:</p>
          <ul className="list-inside text-12px">
            <li className="mb-5 flex items-center">
              <img loading="lazy" width={20} className="mr-4" src={LoginIcon} alt="" />
              <div>
                Login to
                <button type="button" onClick={showToast} className="text-sky-blue underline ml-2">
                  Metator Experiences
                </button>
              </div>
            </li>
            <li className="mb-5 flex items-center">
              <img loading="lazy" width={20} className="mr-4" src={OculusIcon} alt="" /> Use Oculus, Vive or a similar
              headset.
            </li>
            <li className="mb-5 flex items-center">
              <img loading="lazy" width={20} className="mr-4" src={LookAroundIcon} alt="" /> Look around to experience
              the metaverse.
            </li>
          </ul>
        </div>
        {!noBrands && (
          <div className="sm:w-5/12 text-center sm:pl-10">
            <h3 className="uppercase text-20px font-black mt-8 mb-12">Select brands</h3>
            <img
              loading="lazy"
              className="blur-md"
              src={getAssetsDynamically(brandsImage, 'images/experiences')}
              alt=""
            />
          </div>
        )}
      </div>
      <Button
        className="mx-auto mt-[40px] mb-[75px]"
        title="Dive into the Experience"
        variant={color}
        width={300}
        onClick={showToast}
      />
      <Toast isVisible={isVisible} message="This Feature is launching soon, stay tuned." />
    </section>
  );
}

export default About;

About.propTypes = {
  type: PropTypes.string.isRequired,
};

import React from 'react';
// # assets
import { Link } from 'react-router-dom';
import SubscribeToNewsletter from '../components/common/SubscribeToNewsletter';
import StepDivider from '../components/common/StepDivider';
import SocialLinks from '../components/common/SocialLinks';
import CopyrightIcon from '../assets/icons/copyright-icon.png';
import Logo from '../components/icons/Logo';

function Footer() {
  const heroLinks = [
    {
      key: '1',
      title: 'Meta paper',
      path: '/',
    },
    {
      key: '2',
      title: 'Land Sale',
      path: '/',
    },
    {
      key: '3',
      title: 'Marketplace',
      path: '/',
    },
    {
      key: '4',
      title: 'Tokenmetrics',
      path: '/',
    },
    {
      key: '5',
      title: 'Case studies',
      path: '/case-studies',
    },
  ];

  return (
    <footer className="text-white">
      <StepDivider />
      <ul className="bg-black text-12px text-white flex items-center justify-center flex-col sm:flex-row pb-2">
        {heroLinks.map(item => (
          <li
            key={item.key}
            className="border-b-2 border-primary last:border-0 sm:border-0 py-[20px] [&:last-child>span]:border-0"
          >
            <Link to={item.path}>{item.title}</Link>
            <span className="hidden vertical-divider sm:inline-block h-[12px] bg-primary w-0 border-r border-primary mx-[40px]" />
          </li>
        ))}
      </ul>
      <div className="flex justify-between items-center bg-secondary flex-col lg:flex-row px-0 md:px-[100px] lg:px-[80px] xl:px-[150px] 1xl:px-[180px] 3xl:px-[200px] lg:py-[40px] ">
        <div className="w-full lg:w-[40%] order-2 lg:order-1 bg-dark lg:bg-inherit text-center lg:text-left pt-[30px] lg:pt-[60px] pb-[30px] px-[10px] lg:p-0">
          <Logo className="mx-auto lg:mx-0 mb-[16px]" />
          <p className="mb-[40px]">
            MetaTor is a decentralized open metaverse creating opportunities and possibilities beyond reality.
          </p>
          <h2 className="hidden lg:inline-block font-medium mb-[12px] text-xl">CONNECT</h2>
          {/* social links */}
          <SocialLinks className="justify-center lg:justify-start" />
        </div>
        <div className="w-[95%] lg:w-[50%] order-1 lg:order-2 mx-auto lg:m-0 text-center lg:text-left mb-4 lg:mb-0 mt-[40px] lg:mt-0">
          <h2 className="font-semibold mb-[12px] text-xl capitalize">METATOR NEWSLETTER</h2>
          <p className="mb-[30px]">Subscribe to receive all latest news and important announcements of MetaTor.</p>

          <SubscribeToNewsletter />

          <div className="flex text-12px mt-10 gap-12 justify-center lg:justify-start">
            <Link to="/privacy-policy" className="opacity-50 hover:opacity-100">
              Privacy Policy
            </Link>
            <Link to="/terms-and-conditions" className="opacity-50 hover:opacity-100">
              Terms & Conditions
            </Link>
          </div>
        </div>
      </div>
      <div className="bg-primary text-center relative z-[99]">
        <p className="p-[8px]">
          Copyrights <img loading="lazy" src={CopyrightIcon} alt="copyright icon" className="inline-block" /> 2022,
          MetaTor World OÜ, All rights reserved
        </p>
      </div>
    </footer>
  );
}

export default Footer;

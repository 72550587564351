import React, { memo, useState } from 'react';
import axios from 'axios';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';
import Button from '../components/common/button/Button';
import Input from '../components/common/Input/Input';
import LockImage from '../assets/images/lock.png';
import { AUTH_KEY } from '../constants/globals';

function PasswordProtected() {
  const [value, setValue] = useState('');
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const onSubmit = e => {
    axios
      .post('https://ij6a5xkyl3k7fhpwe5uzcae6jq0yhdix.lambda-url.ap-south-1.on.aws/', { key1: value })
      .then(res => {
        if (res && res.data) {
          localStorage.setItem(AUTH_KEY, moment.utc().add(1, 'days').valueOf());
          navigate('/');
        }
      })
      .catch(err => {
        // console.log('err',);
      });
  };

  return (
    <section className="h-[800px] color-white bg-top bg-no-repeat bg-cover password-protected__hero">
      <div className="w-[90%] mx-auto md:w-auto text-center pt-[280px] pb-5">
        <div>
          <img loading="lazy" className="mx-auto mb-[20px] w-[80px] md:w-[136px]" src={LockImage} alt="" />
          <h1 className="text-40px font-bold mb-[16px]">
            <span className="text-primary">MEMBERS</span> ACCESS ONLY
          </h1>
          <p className="mb-[26px]">
            Metator Metaverse requires special entry pass code. Please verify your identity to enter development mode.
          </p>

          <Input
            name="password"
            type="password"
            placeholder="Enter Password"
            error={error}
            className="w-full sm:w-[540px] mx-auto"
            onChange={e => setValue(e.target.value)}
          />
          <Button htmlType="submit" onClick={onSubmit} className="mx-auto mt-[24px]" title="Enter MetaTor" />
        </div>
      </div>
    </section>
  );
}

export default memo(PasswordProtected);

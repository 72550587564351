import { useState } from 'react';

function useToast(props) {
  const [isVisible, setIsVisible] = useState(false);

  const showToast = () => {
    if (!isVisible) {
      setIsVisible(true);
      setTimeout(() => {
        setIsVisible(false);
      }, (props && props.duration) || 3500);
    }
  };

  return { showToast, isVisible };
}

export default useToast;

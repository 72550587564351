import React from 'react';
import Button from '../common/button/Button';
// # assets
import LaunchBG from '../../assets/images/experiences/launch.png';
import useViewportWidth from '../../hooks/useWindow';
import Toast from '../common/Toast/Toast';
import useToast from '../common/Toast/useToast';

function Launch() {
  const isTablet = useViewportWidth() < 992;
  const { showToast, isVisible } = useToast();

  const style = (!isTablet && { backgroundImage: `url(${LaunchBG})` }) || {};

  return (
    <section className="mt-[80px] sm:mt-[120px] lg:mt-[190px] w-full lg:w-[950px] xl:w-[1200px] 2xl:w-[1320px] mx-auto text-center lg:text-start mb-10 lg:mb-0">
      <img loading="lazy" className="block lg:hidden mx-auto" src={LaunchBG} alt="" />
      <div
        className="pt-[30px] sm:pt-[48px] lg:pt-[80px] min-h-[220px] sm:min-h-[340px] lg:min-h-[475px] bg-right bg-no-repeat mx-[20px] lg:mx-0 bg-200px bg-none lg:bg-inherit"
        style={style}
      >
        <h2 className="uppercase text-24px font-bold mb-0">LAUNCH YOUR VR GAME ON</h2>
        <h3 className="text-40px font-black uppercase mb-[12px]">
          METATOR <span className="text-success">LAUNCHPAD</span>
        </h3>
        <p className="mb-[40px] sm:w-1/2 w-[400px] mx-auto lg:mx-0">
          Bring evolution into gaming world by launching your own mixed reality game in MetaTor
        </p>
        <Button
          title="Get Started"
          variant="success"
          className="min-w-[180px] lg:min-w-[240px] mx-auto lg:mx-0"
          onClick={showToast}
        />
      </div>
      <Toast isVisible={isVisible} message="This Feature is launching soon, stay tuned." />
    </section>
  );
}

export default Launch;

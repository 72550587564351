import React from 'react';
import YahooLogo from '../../assets/images/yahoo-logo.png';
import YahooFinanceLogo from '../../assets/images/yahoo-finance.png';
import MarketWatchLogo from '../../assets/images/market-watch-logo.png';
import News10Logo from '../../assets/images/news10-logo.webp';
import APNewsLogo from '../../assets/images/ap-news-logo.png';

function Featured() {
  const featuredData = [
    {
      key: '1',
      className: 'w-[220px]',
      image: YahooLogo,
      title: 'Yahoo Finance',
      link: 'https://www.yahoo.com/now/announcing-metator-mixed-reality-open-170000876.html',
    },
    {
      key: '2',
      className: 'w-[220px]',
      image: MarketWatchLogo,
      title: 'Market Watch',
      link: 'https://www.marketwatch.com/press-release/announcing-metator---a-mixed-reality-open-metaverse-2022-11-30',
    },
    {
      key: '3',
      className: 'w-[160px]',
      image: News10Logo,
      title: 'News10',
      link: 'https://www.news10.com/business/press-releases/ein-presswire/604774047/introducing-metator-open-metaverse-possibilities-beyond-reality/',
    },
    {
      key: '4',
      className: '',
      image: APNewsLogo,
      title: 'AP News',
      link: 'https://apnews.com/press-release/ein-presswire-newsmatics/middle-east-united-arab-emirates-973a521ee561c83a9ae6329f8a5ffc68',
    },
    {
      key: '5',
      className: 'w-[220px]',
      image: YahooFinanceLogo,
      title: 'Yahoo Finance',
      link: 'https://finance.yahoo.com/news/announcing-metator-mixed-reality-open-170000876.html',
    },
  ];

  return (
    <section className="text-center">
      <h2 className="text-24px font-bold mb-[40px] uppercase">MEDIA COVERAGE</h2>
      <div className="flex flex-wrap justify-center items-center gap-8">
        {featuredData.map(({ key, link, image, className }) => (
          <a key={key} href={link} target="_blank" rel="noreferrer">
            <img loading="lazy" className={className} src={image} alt="yahoo.com" />
          </a>
        ))}
      </div>
    </section>
  );
}

export default Featured;

import React from 'react';
import Video from '../common/Video';
import MetaJudiciary from '../../assets/videos/meta-judiciary.mp4';
import Button from '../common/button/Button';
import Toast from '../common/Toast/Toast';
import useToast from '../common/Toast/useToast';

function JudicialSystem() {
  const { showToast, isVisible } = useToast();

  return (
    <section className="lg:flex items-center relative overflow-hidden">
      <div className="w-full flex justify-center lg:justify-end mb-5">
        <Video videSource={MetaJudiciary} autoplay loop />
      </div>

      <div className="px-[20px] sm:px-[40px] lg:px-[80px] xl:px-[140px] 1xl:px-[180px] 2xl:px-[220px] z-10 lg:absolute text-center lg:text-start mb-10 lg:m-0">
        <h2 className="text-24px uppercase mb-[10px] font-medium">MAKING IT SAFE FOR EVERYONE</h2>
        <h3 className="text-40px font-bold uppercase mb-[16px]">
          rule of <span className="text-primary">law</span>
        </h3>

        <p className="mb-[40px] lg:w-4/6">
          Metator is equipped with a law system that ensures safety and security of its Metazens.
        </p>

        <div className="flex items-center justify-center lg:justify-start">
          <Button title="Read more" onClick={showToast} />
        </div>
      </div>

      <Toast isVisible={isVisible} message="This Feature is launching soon, stay tuned." />
    </section>
  );
}

export default JudicialSystem;

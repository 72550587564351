import React from 'react';
// # assets
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import Button from '../components/common/button/Button';
import MetatorFashion from '../assets/images/experiences/metator-fashion.png';
import MetatorGaming from '../assets/images/experiences/metator-gaming.png';
import MetatorLifestyle from '../assets/images/experiences/metator-lifestyle.png';
import MetatorShopping from '../assets/images/experiences/metator-shopping.png';
import MetatorEdutainment from '../assets/images/experiences/metator-edutainment.png';
import MetatorBusiness from '../assets/images/experiences/metator-business.png';
import MetatorExperiences from '../assets/videos/experiences.mp4';
// # components
import PrimaryCard from '../components/common/PrimaryCard';
import Video from '../components/common/Video';
import MetaTags from '../components/common/MetaTags';

function Experiences() {
  const METATOR_SERVICES = [
    {
      title: (
        <>
          METATOR <span className="text-warning">SHOPPING</span>
        </>
      ),
      description:
        'Shopping in MetaTor empowers every customer to shop with freedom and get goods delivered to their doorstep without waiting in long queues.',
      extraContent: (
        <Link to="/experiences/shopping">
          <Button title="Experience now" className="ml-auto" variant="warning" />
        </Link>
      ),
      poster: MetatorShopping,
      key: '1',
    },
    {
      type: 'divider',
      key: '2',
    },
    {
      title: (
        <>
          METATOR <span className="text-purple">FASHION</span>
        </>
      ),
      description:
        'Enter into the world of glamour and fashion to embrace new art and designs collection of the industry.',
      extraContent: (
        <Link to="/experiences/fashion">
          {' '}
          <Button title="Experience now" className="ml-auto" variant="purple" />
        </Link>
      ),
      poster: MetatorFashion,
      key: '3',
    },
    {
      title: (
        <>
          METATOR <span className="text-success">GAMING</span>
        </>
      ),
      description: "Gaming is going to be interactive and full of players' engagement in the virtual world of MetaTor.",
      extraContent: (
        <Link to="/experiences/gaming">
          {' '}
          <Button title="Experience now" className="ml-auto" variant="success" />
        </Link>
      ),
      poster: MetatorGaming,
      key: '4',
    },
    {
      type: 'divider',
      key: '5',
    },
    {
      title: (
        <>
          METATOR <span className="text-warning-800">LIFESTYLE</span>
        </>
      ),
      description:
        'Embrace technology and live a standard lifestyle where smart solutions lead to making better decisions in your life.',
      extraContent: <p className="color-info font-semibold text-end text-12px">Coming soon</p>,
      poster: MetatorLifestyle,
      key: '6',
    },
    {
      title: (
        <>
          METATOR <span className="text-danger">EDUTAINMENT</span>
        </>
      ),
      description:
        'Spreading education is about to become more active and efficient with an interactive education system being introduced in MetaTor.',
      extraContent: <p className="color-info font-semibold text-end text-12px">Coming soon</p>,
      poster: MetatorEdutainment,
      key: '7',
    },
    {
      type: 'divider',
      key: '8',
    },
    {
      title: (
        <>
          METATOR <span className="text-info">Business</span>
        </>
      ),
      description:
        'Revolutionizing the landscape of business,  providing a variety of opportunities and business setup options for everyone.',
      extraContent: <p className="color-info font-semibold text-end text-12px">Coming soon</p>,
      poster: MetatorBusiness,
      key: '9',
    },
  ];

  return (
    <>
      <MetaTags page="experiences" />
      <section className="relative h-[400px] md:h-[600px] xl:h-[800px] overflow-hidden">
        <div className="flex items-end justify-center w-full h-full text-center absolute z-10 gradient-from-bottom">
          <div className="1xl:pb-[160px]">
            <h1 className="uppercase font-black mb-[20px]">
              <span className="block text-34px">WELCOME TO</span>
              <span className="text-40px">
                METATOR <span className="text-primary">EXPERIENCES</span>{' '}
              </span>
            </h1>
            <p>
              Experience this new world of opportunities and advancements yourself. Every experience reflects the vision
              behind MetaTor.
            </p>
          </div>
        </div>

        <div className="relative">
          <Video videSource={MetatorExperiences} autoplay loop muted />
          <div className="absolute w-full h-full top-0 gradient-from-bottom-50" />
        </div>
      </section>
      {/* mb-[90px] w-[750px] xl:w-[1000px] 1xl:w-[1200px] 2xl:w-[1350px] mx-auto */}
      <section className="w-5/6 2xl:w-[1440px] mt-[90px] mx-auto lg:flex justify-between items-center flex-wrap gap-y-20">
        {METATOR_SERVICES.map(({ title, description, extraContent, containerClassName, poster, key, type }) =>
          type === 'divider' ? (
            <div className="hidden lg:block h-[120px] border border-white opacity-50" key={key} />
          ) : (
            <PrimaryCard
              key={key}
              containerClassName={clsx('mb-16 lg:mb-0 lg:w-[47%]', containerClassName)}
              extraContent={extraContent}
              description={description}
              primaryTitle={title}
              poster={poster}
              className="min-h-[220px] sm:min-h-[380px]"
            />
          )
        )}
      </section>

      <section className="w-5/6 2xl:w-[1440px] mx-auto mt-[80px] lg:mt-[140px] mb-0 lg:mb-[100px] text-center">
        <h3 className="text-12px mb-[10px]">METATALK</h3>
        <h2 className="uppercase font-black mb-[10px] text-28px">METATOR EXPERIENCES</h2>
        <p className="text-14px mb-[27px] lg:w-3/4 mx-auto">
          Team Metator speaks about how experiences can change the way we see the world today. Watch full Podcast on
          MetaTalk Youtube channel.
        </p>
        <p className="my-10 lg:my-[100px] text-12px text-info font-medium  font-secondary">Talks coming soon...</p>
      </section>
    </>
  );
}

export default Experiences;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import './dropdown.css';
// # assets
import clsx from 'clsx';
import { ReactComponent as ArrowDown } from '../../../assets/icons/arrow-down.svg';
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow-right.svg';

function Dropdown({ items, title, icon, path = '/', key }) {
  const [show, setShow] = useState(false);

  const toggleMenu = () => setShow(!show);

  return (
    <div key={key} onMouseEnter={toggleMenu} onMouseLeave={toggleMenu} className="dropdown">
      <Link className="inline-block" to={path}>
        {title}{' '}
        <ArrowDown className={clsx('ml-2 path:stroke-primary transition-all', show ? 'rotate-180' : 'rotate-0')} />
      </Link>

      {show && (
        <ul className="dropdown__menu">
          {items.map(({ title, key, path, hoverBG }) => (
            <NavLink to={path} key={key}>
              <li className={`[&>svg]:hover:translate-x-2 bg--${hoverBG}`}>
                <span>{title}</span>
                {icon && <ArrowRight className="transition-all duration-500" />}
              </li>
            </NavLink>
          ))}
        </ul>
      )}
    </div>
  );
}

export default Dropdown;

Dropdown.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      key: PropTypes.string,
      path: PropTypes.string,
    })
  ),
  title: PropTypes.string,
  icon: PropTypes.bool,
  path: PropTypes.string,
};

export function MobileDropdown({ items = [], title = '', handleDropdownItemClicked }) {
  const [open, setOpen] = useState(false);
  return (
    <div className="flex flex-col items-center gap-y-5 [&:last-child>span]:hidden [&:last-child]:mb-5">
      <li className="h-[40px] leading-[40px]">
        <button type="button" onClick={() => setOpen(!open)}>
          {title}{' '}
          <ArrowDown className={clsx('ml-2 path:stroke-primary transition-all', open ? 'rotate-180' : 'rotate-0')} />
        </button>
      </li>
      {open &&
        items.map(({ title, key, path }) => (
          <li className="h-[40px] leading-[40px]" key={key}>
            <button type="button" className="hover:text-primary" onClick={handleDropdownItemClicked}>
              <NavLink to={path}>
                <span>{title}</span>
              </NavLink>
            </button>
          </li>
        ))}
    </div>
  );
}

/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Slider from 'react-slick';
// # assets
import clsx from 'clsx';
import { ReactComponent as NextIcon } from '../../assets/icons/next-icon.svg';
import { ReactComponent as PrevIcon } from '../../assets/icons/prev-icon.svg';
import SlickArrow from './SlickArrow';

function SlickImageGallery({ className = '', images = [] }) {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const settings = {
    // eslint-disable-next-line react/no-unstable-nested-components
    customPaging(i) {
      return (
        <div className="slick-dot">
          {images[i] && images[i].content && <img loading="lazy" src={images[i].content} alt={`image ${i}`} />}
        </div>
      );
    },
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: !isSafari ? <SlickArrow direction="next" icons={{ next: <NextIcon /> }} /> : false,
    prevArrow: !isSafari ? <SlickArrow direction="prev" icons={{ prev: <PrevIcon /> }} /> : false,
  };

  return (
    <div className={clsx('primary-slick-gallery max-h-[800px]', className)}>
      <Slider {...settings}>
        {images.map(({ content, key }) => (
          <div key={key}>
            <img loading="lazy" src={content} alt="Slide1" />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default SlickImageGallery;

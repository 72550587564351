import React from 'react';
import EmissionComparison from '../../assets/images/case-studies/4/1.jpg';
import CarbonEmissionBySource from '../../assets/images/case-studies/case-study-4.jpg';

function CaseStudy4() {
  return (
    <div className="text-14px font-light">
      <p className="mb-4">
        Metaverse refers to a digital environment that uses state-of-the-art technologies to replicate the real world.
        Establishing a connection between the Metaverse, climate crisis, and de-carbonization may seem far-fetched,
        however, there exists a synergy between these terms that can be helpful in the fight for a more
        environmentally-friendly and sustainable planet.
      </p>
      <p className="mb-4">
        Climate change is a complex and multi-faceted problem that requires a comprehensive and coordinated response. As
        the climate crisis is taking a serious toll on communities all over the world, we need to act now to ensure that
        future generations have a safe and healthy environment.
      </p>
      <p className="mb-4">
        A sustainable planet requires rigorous collaboration to diminish the carbon footprint of all the products and
        services consumed by us in our daily lives, including technology, buildings, clothing, food, furniture,
        transportation, and other goods. Many global organizations are already on track to achieve net zero emissions.
      </p>

      <img loading="lazy" className="my-10 mx-auto block" src={CarbonEmissionBySource} alt="CO emission by source" />

      <p className="mb-8">
        Metaverse is creating virtual testbeds that can help solve climate problems so that we can find and test new and
        innovative ways to reduce greenhouse gas emissions. Here&apos;s how the metaverse can play an important role in
        mitigating the climate crisis.
      </p>

      <h2 className="text-primary font-semibold text-20px mb-2">Lowering over-dependence on physical spaces</h2>
      <p className="mb-4">
        We are troubled by the harm our environmental practices have caused to the natural ecosystem. With
        globalization, we&apos;ve seen far more of the planet at an unprecedented pace, without leaving any area
        untouched. Don&apos;t the weary landscapes need a break?
      </p>
      <p className="mb-4">
        We could take some of the tourism practices we use in the real world and apply them to the virtual world. This
        can offer some much-needed relief for the cities and landscapes surrounding us.
      </p>
      <p className="mb-8">
        Tourist attractions in virtual worlds may prove to be a more environmentally-friendly approach than traditional
        methods, utilizing fewer natural resources that are already in danger of being depleted.
      </p>
      <h2 className="text-primary font-semibold text-20px mb-2">
        Lowering the amount of carbon and greenhouse gases emitted by buildings
      </h2>
      <p className="mb-4">
        Reduction in carbon and greenhouse gas emissions means reducing the amount of pollution created by burning
        fossil fuels which are essential in combating climate issues. Minimizing the carbon dioxide emissions from
        industrial processes is essential as well in order to meet global temperature standards.
      </p>
      <p className="mb-4">
        Metaverse has the potential to help reduce greenhouse gas and other harmful emissions into the atmosphere. With
        metaverse, digital replicas of real-world buildings are created that use less energy, leading to significant
        depletion of emissions. Furthermore, it allows users to track and report how a site or building is performing in
        real-time such as the changes in humidity, temperature, energy consumption, etc.
      </p>
      <p className="mb-4">
        When you can collect reliable data about the environment as it changes, you can make more informed decisions
        about how to deal with the situation. Once a user is well-adapted to their surrounding environment, systems can
        be managed based on predetermined thresholds, and specific regulatory parameters.
      </p>
      <p className="mb-8">
        Similarly, customizable lights and temperature settings can help conserve energy by automatically reducing usage
        when it is not needed (e.g. based on the time of the day or whenever the sensor detects nobody is in the room).
      </p>
      <h2 className="text-primary font-semibold text-20px mb-2">Lowering the amount of waste produced by textiles</h2>
      <p className="mb-4">
        In today&apos;s era, many clothing manufacturers intend to adopt more sustainable and eco-friendly materials in
        the future. While using natural materials to make clothing is an environmentally friendly option, it still has
        some unintended consequences. The staggering amount of clothing that is wasted each year highlights the pressing
        need to shift to a more sustainable fashion approach.
      </p>
      <p className="mb-8">
        As our shopping preferences continue to change, the Metaverse provides an easy way to add new outfits to our
        wardrobes without having to purchase them physically. With wearable technology continuing to grow in popularity,
        fashion brands can offer even more exciting and engaging augmented experiences for their consumers.
      </p>
      <h2 className="text-primary font-semibold text-20px mb-2">
        Lowering the amount of emissions from travel-related activities
      </h2>
      <p className="mb-4">
        The metaverse has tremendous potential to displace both ground-based and airborne travel for leisure and
        business purposes. Air travel has played a minor but significant part in global emissions. Prior to the
        pandemic&apos;s outbreak, 2.5% of worldwide emissions were attributable to air travel; however, following the
        pandemic, emissions from this sector were reduced by half.
      </p>
      <img
        loading="lazy"
        className="my-10 mx-auto block w-full"
        src={EmissionComparison}
        alt="Carbon emission comparison"
      />
      <p className="mb-4">
        Amidst the pandemic, businesses and consumers realized that videoconferencing is a viable alternative to
        in-person meetings for a variety of purposes. From meetings to virtual happy hours, video conferencing became a
        popular choice for getting work done.
      </p>
      <p className="mb-4">
        Now imagine attending a conference in the metaverse where people can engage in genuine, personal interaction
        without the hassle, cost, or time-consuming logistics of routine travel. This is already taking place with
        Metaverse-based concerts that are drawing in thousands of fans from all over the world. If they were to take
        place in the physical world, this would have ignited a wave of travel and hence more global emissions.
      </p>
      <p className="mb-8">
        While face-to-face interactions remain always crucial, metaverse travel may eventually replace many unnecessary
        trips.
      </p>
      <h2 className="text-primary font-semibold text-20px mb-2">
        How does MetaTor intend to lessen the effects of the climate crisis?
      </h2>
      <p className="mb-4">
        MetaTor is especially focused on the settlement of all real-world concerns and challenges, climate catastrophe
        being one of them. With{' '}
        <a
          href="https://metator.com/static/media/metator-shopping.0598e55793ee1914dc49.mp4"
          target="_blank"
          rel="noreferrer"
        >
          <u>shopping in MetaTor</u>
        </a>{' '}
        for your favorite sports and brands will become a cleaner and more sustainable experience. This will be a
        revolution for the environment and will be a boon to everyone involved.
      </p>
      <p className="mb-4">
        To let the residents of MetaTor visit their{' '}
        <a
          href="https://metator.com/static/media/metator-welcome.fb9b7bcdc3e23e1dcc2e.mp4"
          target="_blank"
          rel="noreferrer"
        >
          <u>favorite locations</u>
        </a>{' '}
        throughout the world and lessen the gasoline or CO2 emissions produced by the transportation industry in the
        real world, a sizable transportation infrastructure with vehicles, boats, helicopters, and all other transport
        channels will be available.
      </p>

      <p className="mb-4">
        Moreover, MetaTor helps to conserve energy sources such as green energy and renewable energy, while also
        contributing to potential savings for people. Briefly stated, MetaTor provides people with a unique outlook,
        freedom, and real-world value by fostering the well-being and sustainability of the planet.
      </p>
    </div>
  );
}

export default CaseStudy4;

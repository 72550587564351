import React from 'react';
import Slider from 'react-slick';
import { ReactComponent as NextIcon } from '../../assets/icons/next-icon.svg';
import { ReactComponent as PrevIcon } from '../../assets/icons/prev-icon.svg';
import SlickArrow from '../common/SlickArrow';

function TeamSlider({ data }) {
  const settings = {
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    infinite: true,
    fade: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SlickArrow direction="next" icons={{ next: <NextIcon className="w-[12px]" /> }} />,
    prevArrow: <SlickArrow direction="prev" icons={{ prev: <PrevIcon className="w-[12px]" /> }} />,
  };

  return (
    <div>
      <div className="secondary-slick-slider">
        <Slider {...settings}>
          {data.map(({ image, key, name }) => (
            <div key={key}>
              <img loading="lazy" width={140} className="mx-auto" src={image} alt={name} />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default TeamSlider;

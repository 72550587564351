import React from 'react';
// # assets
import TeamWithBG from '../assets/images/meta-team/TeamWithBG.png';
import Anchor from '../components/common/Anchor';
import TeamCards from '../components/AboutUs/TeamCards';
import TeamTrigger from '../components/AboutUs/TeamTrigger';

function AboutUs() {
  return (
    <>
      <section className="relative z-10">
        <div className="absolute top-0 w-full text-center">
          <h2 className="text-24px font-bold mt-[120px]">GET TO KNOW MORE ABOUT US</h2>
          <h1 className="text-60px font-black">
            WE&apos;RE <span className="text-primary">METATOR</span>
          </h1>
        </div>
        <img loading="lazy" width="100%" src={TeamWithBG} alt="team metator" />
      </section>

      <div className="pl-[475px] pr-[120px] flex about-team-wrapper">
        <Anchor
          className="fixed top-[500px] left-0 mx-[120px] anchor-active-box:bg-primary anchor-active-box:border-transparent anchor-active:text-primary anchor-active:font-bold"
          sectionsClassName="pt-[90px] [&>section]:pb-[240px]"
          triggers={[
            {
              key: 1,
              title: <TeamTrigger name="core" />,
              to: 'section1',
            },
            {
              key: 2,
              title: <TeamTrigger name="design" />,
              to: 'section2',
            },
            {
              key: 3,
              title: <TeamTrigger name="development" />,
              to: 'section3',
            },
            {
              key: 4,
              title: <TeamTrigger name="blockchain" />,
              to: 'section4',
            },
            {
              key: 5,
              title: <TeamTrigger name="marketing" />,
              to: 'section5',
            },
          ]}
          sections={[
            {
              key: '1',
              to: 'section1',
              content: <TeamCards teamName="core" />,
            },
            {
              key: '2',
              to: 'section2',
              content: <TeamCards teamName="design" />,
            },
            {
              key: '3',
              to: 'section3',
              content: <TeamCards teamName="development" />,
            },
          ]}
        />
      </div>
    </>
  );
}

export default AboutUs;

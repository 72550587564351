import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as RightOutlined } from '../../assets/icons/right-outlined.svg';
import Button from './button/Button';
import { ReactComponent as MetatorIco } from '../../assets/icons/metator-ico.svg';

function PrimaryCard({
  description = null,
  primaryTitle = null,
  URL = '',
  poster,
  minHeight = 500,
  className = '',
  extraContent = null,
  containerClassName = '',
  posterClassName = '',
  variant = 'primary',
  date = 'November 22, 2022',
  Icon = null,
  buttonURL = '',
  isSecondarySm = false,
  ...rest
}) {
  const navigate = useNavigate();

  return variant === 'secondary' ? (
    <Link to={URL} className={('block', clsx(containerClassName))}>
      <div className={clsx('relative mb-5 p-[12px] sm:p-[24px] pb-0', className)} {...rest}>
        <div className={clsx('relative w-full h-[160px] sm:h-[240px] xl:h-[320px]', posterClassName)}>
          <img loading="lazy" className="object-cover w-full h-full" src={poster} alt="" />
          <div className="absolute w-full h-full top-0 gradient-from-bottom-50" />
        </div>
        <div className="sm:-mt-10 relative z-10">
          <h2 className={clsx('font-bold mb-3', isSecondarySm ? 'text-14px' : 'text-20px')}>{primaryTitle}</h2>
          <div className="flex justify-between items-center">
            {!isSecondarySm && (
              <div className="flex items-center gap-2">
                <MetatorIco className="[&>path]:fill-primary w-[14px] sm:w-[20px]" /> <span>MetaTor</span>
              </div>
            )}
            <span>
              <p className={clsx('font-light text-gray', isSecondarySm ? 'text-12px' : 'text-14px')}>{date}</p>
            </span>
          </div>
          <div className={clsx('relative mb-[12px] mt-[16px] max-w-[800px', isSecondarySm ? 'text-12px' : 'text-14px')}>
            {description}
            <div className="absolute w-full h-full top-0 gradient-from-bottom" />
          </div>

          <Button
            className={clsx('ml-auto', isSecondarySm && 'text-12px')}
            type="text"
            title="Read more"
            suffix={<RightOutlined className="[&>path]:stroke-primary" />}
          />
        </div>
      </div>
      {extraContent}
    </Link>
  ) : (
    <div className={clsx(containerClassName)}>
      <div
        className={clsx('flex items-end bg-cover bg-bottom bg-no-repeat mb-5 p-[12px] sm:p-[24px] pb-0', className)}
        {...rest}
        style={{ backgroundImage: `url(${poster})` }}
      >
        <div>
          <h2 className="text-28px font-bold uppercase">{primaryTitle}</h2>
          <p className="mb-[12px] mt-[16px] max-w-[800px] text-14px">{description}</p>
        </div>
      </div>
      {extraContent}
    </div>
  );
}

export default PrimaryCard;

PrimaryCard.propTypes = {
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  URL: PropTypes.string,
  poster: PropTypes.string,
  minHeight: PropTypes.number,
  extraContent: PropTypes.node,
};

import React from 'react';
import BGMainMobile from '../../assets/images/bg-main-mobile.png';
import HeroVideo from '../../assets/videos/home-hero.mp4';
import BinanceLogo from '../../assets/images/binance-logo.svg';
import EthereumLogo from '../../assets/images/ethereum-logo.svg';
import SolanaLogo from '../../assets/images/solana-logo.svg';
import PolygonLogo from '../../assets/images/polygon-logo.png';
import useViewportWidth from '../../hooks/useWindow';
import Button from '../common/button/Button';

function HeroSection() {
  const isMobile = useViewportWidth() < 992;

  return (
    <section className="w-full lg:h-max flex flex-col sm:flex-row lg:items-center lg:justify-center mt-[10px] lg:pb-0 relative">
      {!isMobile && (
        <video autoPlay width="100%" muted loop>
          <track kind="captions" />
          <source src={HeroVideo} type="video/mp4" />
        </video>
      )}
      {/* mobile bg */}
      <img loading="lazy" src={BGMainMobile} className="w-full object-cover object-top block lg:hidden" alt="" />

      <div className="sm:absolute w-full h-full gradient-from-bottom flex items-center justify-center mb-[40px] sm:mb-0">
        <div className="text-center mx-[16px] sm:mx-[40px] mt-[-60px] 2xl:mt-[120px]">
          <h2 className="text-24px uppercase mb-[10px] font-medium">Explore and live</h2>
          <h1 className="text-40px font-bold uppercase mb-[16px]">
            Possibilities beyond <span className="text-primary">reality</span>{' '}
          </h1>
          <p className="mb-[30px] sm:w-3/4 mx-[10px] sm:mx-auto">
            Presenting MetaTor, a decentralized and open metaverse to experience life beyond reality.
          </p>

          <a href="#explore">
            <Button className="mx-auto" title="Explore Experience" />
          </a>

          <div className="flex items-center justify-center sm:mx-auto mt-[50px] gap-y-[20px] gap-x-[16px] sm:gap-x-[30px] md:gap-x-[70px] flex-wrap">
            <img
              loading="lazy"
              src={BinanceLogo}
              alt="binance logo"
              className="w-[40px] xsm:w-[60px] sm:w-[100px] lg:w-[131px]"
            />
            {/* <img
              loading="lazy"
              src={EthereumLogo}
              className="w-[75px] xsm:w-[100px] sm:w-[130px] lg:w-[178px]"
              alt="ethereum logo"
            />
            <img
              loading="lazy"
              src={SolanaLogo}
              alt="solana logo"
              className="w-[75px] xsm:[100px] sm:w-[120px] lg:w-[152px]"
            />
            <img
              loading="lazy"
              src={PolygonLogo}
              alt="polygon logo"
              className="w-[75px] xsm:[100px] sm:w-[124px] lg:w-[152px]"
            /> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;

import React from 'react';
import VideoPlayer from '../common/VideoPlayer';
import LivingDeadVideo from '../../assets/videos/living-dead.mp4';
import MetatorLivingDead from '../../assets/images/posters/metator-living-dead.png';

function LivingDead() {
  return (
    <section className="mb-10">
      <VideoPlayer
        videSource={LivingDeadVideo}
        description="Death is a reality but you can meet your loved ones even after they're gone. People
                will be able to visit and meet the Ai Driven interactive souls of their friends &
                family."
        primaryTitle={
          <div className="text-40px">
            <h2 className="text-24px uppercase mb-[10px] font-medium">DIE IN THE REAL WORLD</h2>
            LIVE FOREVER IN <span className="text-primary">METATOR</span>
          </div>
        }
        poster={MetatorLivingDead}
        className="gradient-from-bottom-50"
        wrapperClassName="md:min-h-[460px] lg:min-h-full"
      />
    </section>
  );
}

export default LivingDead;

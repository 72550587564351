import React from 'react';
// # assets
import PartnersImage from '../../assets/images/experiences/partners.svg';

function Partners() {
  return (
    <section className="mb-[90px] mx-[20px] sm:mx-[40px] lg:mx-auto">
      <div className="text-center">
        <h3 className="uppercase text-20px font-black mt-4 mb-3">TECHNOLOGY PARTNERS</h3>
        <p className="mb-[40px] lg:mb-[60px]">Metator Metaverse is available on</p>

        <img loading="lazy" className="w-full lg:w-[900px] blur-md" src={PartnersImage} alt="" />
      </div>
    </section>
  );
}

export default Partners;

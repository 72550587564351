import React from 'react';
import ElementsOfMetaversePoster from '../../assets/images/case-studies/1/1.jpg';
import ConsumerUsePoster from '../../assets/images/case-studies/1/2.jpg';

function CaseStudy1() {
  return (
    <div className="text-14px font-light">
      <h2 className="text-primary font-semibold text-20px mb-2">About Metaverse</h2>
      <p className="mb-4">
        There are always new technological advancements that promise to improve our lives in ways we never imagined
        possible. A few years ago, we saw a significant shift in how people communicated and interacted with one another
        and their surroundings through social media platforms. We are on the brink of a new era in which the internet of
        things and “The Metaverse” have come to prominence.
      </p>
      <p>
        The COVID-19 pandemic sped up the intermingling of both real and virtual worlds, which has prompted the
        development of complementary and innovative technologies. The Metaverse, a virtual world where people can be
        whoever they want to be, has become a significant part of our daily lives.
      </p>

      <img loading="lazy" className="my-10 mx-auto block" src={ElementsOfMetaversePoster} alt="Elements of metaverse" />

      <h2 className="text-primary font-semibold text-20px mb-2">Strategy and Goals</h2>
      <p className="mb-4">
        While online platforms can be a great way to get things done, they can’t always be relied upon to replace the
        physical interaction necessary for certain activities. The Metaverse has proposed a new way to bridge the
        digital-physical divide, creating a unique, immersive experience that feels like you’re actually present without
        having to be physically there.
      </p>
      <p className="mb-10">
        The metaverse encompasses a wide range of technologies, including gaming, virtual reality, blockchain, machine
        learning, digital currencies, sensors, 3-D graphics, and VR-enabled headsets. Within the Metaverse, you can
        create and share your own experiences with others, shop for the items you need, and attend concerts and other
        entertainment events.
      </p>
      <h2 className="text-primary font-semibold text-20px mb-2">Banking in the Metaverse: A Fintech Shift</h2>
      <p className="mb-4">
        There are many reasons why traditional banking needs some improvements. For instance, many people are unable to
        use traditional banking services due to location constraints or simply because of their bank’s reluctance to
        offer certain services.
      </p>
      <p className="mb-4">
        In addition, traditional banking systems are often slow and cumbersome, hindering people’s ability to process
        transactions efficiently. Similarly, long wait times at banks when trying to submit bills and fees can be
        frustrating.
      </p>
      <p className="mb-4">
        Digital banking has been a success for banks, but this shift has come at the cost of some of their customers’
        emotional connections. They no longer have the ability to connect with people on a personal level.
      </p>
      <p className="mb-4">
        The Metaverse has the potential to restore the ability for customers and bankers to communicate again, putting
        humanity back into banking. Banks could potentially benefit from the development of a metaverse, as it could
        lead to more efficient customer interactions.
      </p>
      <p className="mb-4">
        According to 47% of bankers, instead of traditional channels, clients will increasingly use augmented reality
        (AR) or virtual reality (VR) to conduct transactions by 2030.
      </p>

      <img
        loading="lazy"
        className="my-10 mx-auto block"
        src={ConsumerUsePoster}
        alt="Consumer use of VR and AR for Transactions"
      />

      <p className="mb-4">
        Imagine that you will never have to take a break during business hours and keep waiting in long queues at the
        bank. Imagine having a personalized banking service at home, where you can be comfortable and have control over
        your finances.
      </p>

      <p className="mb-4">
        Within the Metaverse, a bank can provide its customers with a virtual financial town where they can explore
        their respective branches and access their accounts from anywhere in the world.
      </p>

      <p className="mb-4">
        This would be a mutually beneficial arrangement for both customers and banks. Customers would be able to get
        their services whenever and wherever they want, while banks would have the option to lower their costs by not
        having to put resources into physical locations.
      </p>
      <p className="mb-10">
        Here are just a few of the ample opportunities that the metaverse can offer the banking industry.
      </p>

      <h2 className="text-primary font-semibold text-20px mb-2">Convenience of making transactions in the Metaverse</h2>
      <p className="mb-10">
        The potential for the metaverse to revolutionize the way we do banking is clear. Cryptocurrencies enable secure
        and quick financial transactions in the Metaverse because of their use of blockchain technology. The metaverse
        is much more than just cryptocurrencies and blockchain. With Metaverse virtual assistants and wallets, there is
        a world of convenience and efficiency that traditional banking lacks.
      </p>

      <h2 className="text-primary font-semibold text-20px mb-2">Training in the Metaverse</h2>
      <p className="mb-4">
        With VR and AR technology, not just customer experiences will transform, but how banks train their employees
        will too. The potential benefits of utilizing metaverse concepts in technology can help trainers better simulate
        conversations, which can provide valuable training opportunities for employees and allow them to feel realistic
        training environments that were impractical with traditional finance.
      </p>
      <p className="mb-10">
        In particular, Bank of America is making a big commitment to virtual reality training for its staff members.
        Nearly 4,300 of its workplaces nationwide will offer VR training, making it an effective way to improve employee
        skills.
      </p>

      <h2 className="text-primary font-semibold text-20px mb-2">Intensification of customer care</h2>
      <p className="mb-4">
        Banking has become more practical, but it still lacks emotional connection. The Metaverse enables banks to
        connect with customers in a more significant way, strengthening relationships that have been lost in traditional
        banking.
      </p>

      <p className="mb-10">
        Customers will be able to access a wide range of personalized services within virtual branches, including
        interactions with a mortgage broker, having a retirement discussion with an avatar consultant, community-driven
        initiatives supported by banks, and many more. These services will be available in real-time, so customers can
        get the help they need right away.
      </p>

      <h2 className="text-primary font-semibold text-20px mb-2">
        What role, specifically, is MetaTor playing in the transformation of the banking industry?
      </h2>
      <p className="mb-4">
        “The MetaTor” Metaverse, which is a decentralized virtual reality platform powered by multichain, is a vital
        part of a larger effort to improve the future of banking, which is critical to the overall well-being of
        society. Customers’ access to and utilization of financial services is being facilitated by MetaTor, which
        contributes to the streamlining, accessibility, and user-friendliness of the banking process.
      </p>
      <p className="mb-4">
        MetaTor Bank will issue all the $MTOR currency and in fact MetaTor debit cards as well. MetaTor Bank will be
        accessible to everyone, and more branches of MetaTor Bank will be introduced in different countries and cities
        of the MetaTor virtual world.
      </p>
      <p className="mb-4">
        MetaTor Bank will be open to everyone, regardless of location or socio-economic status. It is committed to
        making your transactions as smooth and effortless as possible. With the support of the MetaTor team’s financial
        professionals, the latest banking solutions will be available to everyone living in the MetaTor.
      </p>
      <p className="mb-4">
        The MetaTor world of finance and banking is going to be more dynamic and easy to use than ever before and it
        will continue to develop its online banking services, making them more accessible and convenient for everyone.
      </p>
    </div>
  );
}

export default CaseStudy1;

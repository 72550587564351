import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import './button.css';
import ArrowRight from '../../../assets/icons/arrow-right.png';
import Loader from '../../icons/Loader';

function Button({
  title = '',
  icon = ArrowRight,
  onClick = () => {},
  className = '',
  variant = 'primary',
  type = 'primary',
  textSpacing = '!px-[18px] lg:px-[24px]',
  noAnimation = false,
  htmlType = 'button',
  textStyle = {},
  loading = false,
  suffix = null,
  children,
  ...rest
}) {
  return type === 'text' ? (
    <button type="button" className={clsx('flex items-center', className)}>
      {' '}
      {title}
      {suffix && <div className="ml-3">{suffix}</div>}
    </button>
  ) : (
    <button
      // eslint-disable-next-line react/button-has-type
      type={htmlType}
      className={clsx('text-[18px]', `btn btn-${type}`, noAnimation && `bg-${variant}`, className)}
      onClick={onClick}
      style={{ borderColor: `var(--color-${variant})` }}
      {...rest}
    >
      {loading ? (
        <Loader className="mx-auto" />
      ) : (
        <>
          <span
            className={clsx(noAnimation && '!transition-none', textSpacing)}
            style={{
              backgroundImage: `linear-gradient(var(--color-${variant}), var(--color-${variant}))`,
              ...textStyle,
            }}
          >
            {title}
          </span>
          {icon && (
            <span
              className={clsx(noAnimation && '!transition-none')}
              style={{
                backgroundImage: `linear-gradient(var(--color-${variant}), var(--color-${variant}))`,
              }}
            >
              <img loading="lazy" className="inline-block" src={icon} alt="arrow icon" />
            </span>
          )}
        </>
      )}
    </button>
  );
}

export default Button;

Button.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool, PropTypes.node]),
  onClick: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.oneOf(['primary', 'secondary', 'text']),
  variant: PropTypes.oneOf(['primary', 'warning', 'purple', 'success', 'danger', 'info', 'warning-800']),
  textSpacing: PropTypes.string,
  noAnimation: PropTypes.bool,
  htmlType: PropTypes.oneOf(['button', 'submit', 'reset']),
  textColor: PropTypes.string,
};

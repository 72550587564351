import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-scroll';
import PropTypes from 'prop-types';

/**
 * @description - This component is used to create a list of links that will scroll to a section on the page.
 *              - Sections are the content you want to display. i.e. to property is used to
 *              - ID is mandatory for each section.
 *              - Under the hood, this component uses react-scroll.
 * @param {Array} triggers - Array of objects. Each object is a trigger. Each trigger has a key, title and to.
 * @param {Array} sections - Array of objects. Each object is a section. Each section has a key, to and content.
 * @param {String} className - Class name for the list of triggers.
 * @param {String} sectionsClassName - Class name for the sections.
 * @param {Object} config - Configuration for react-scroll.
 *
 * @example - <Anchor triggers={[{ key: 1, title: "title", to: "section1" }]} sections={[{ key: '1', to: "section1", content: <div>...</div> }]} />
 *
 * @ return {JSX}
 */

function Anchor({
  triggers = [],
  sections = [],
  className,
  sectionsClassName,
  config = { spy: true, smooth: true, offset: -595, duration: 100 },
}) {
  return (
    <>
      <ul className={clsx(className)}>
        {triggers.map(({ key, title, to }) => (
          <Link
            className="cursor-pointer"
            key={key}
            to={to}
            spy={config.spy}
            smooth={config.smooth}
            offset={config.offset}
            duration={config.duration}
          >
            {title}
          </Link>
        ))}
      </ul>
      <div className={clsx(sectionsClassName)}>
        {sections.map(({ key, to, content }) => (
          <section key={key} id={to}>
            {content}
          </section>
        ))}
      </div>
    </>
  );
}

export default Anchor;

Anchor.prototype = {
  triggers: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number.isRequired,
      title: PropTypes.element.isRequired,
      to: PropTypes.string.isRequired,
    })
  ),
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number.isRequired,
      to: PropTypes.string.isRequired,
      content: PropTypes.element.isRequired,
    })
  ),
  className: PropTypes.string,
  sectionsClassName: PropTypes.string,
  config: PropTypes.shape({
    spy: PropTypes.bool,
    smooth: PropTypes.bool,
    offset: PropTypes.number,
    duration: PropTypes.number,
  }),
};

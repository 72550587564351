import React from 'react';
import ShoppingExperience from '../../assets/images/case-studies/5/1.jpg';

function CaseStudy5() {
  return (
    <div className="text-14px font-light">
      <p className="mb-4">
        The new frontier for retailers seeking to attract digitally savvy customers who appear to be excited about the
        future of online shopping is likely to be a virtual store. As the Metaverse continues to grow in popularity, the
        shopping experience is changing rapidly. Online retailers are starting to offer the same types of services that
        brick-and-mortar stores do, and many virtual malls are popping up to provide a more comprehensive shopping
        experience.
      </p>

      <img
        loading="lazy"
        className="my-10 mx-auto block"
        src={ShoppingExperience}
        alt="Redefining shopping experience"
      />

      <p className="mb-4">
        You&apos;ll be able to shop for virtually anything and experience it firsthand without ever having to leave your
        home. This is a major advantage for both consumers and retailers, as it brings more customers into the fold and
        reduces the need for them to travel to physical stores. So whether you&apos;re up for a new laptop or a new
        dress, the Metaverse is the perfect place to shop. Overall, the shopping experience in the Metaverse is becoming
        more and more convenient and user-friendly.
      </p>
      <p className="mb-8">
        The Metaverse offers a wealth of opportunities for brands and customers alike, through revolutionary processes
        that will fix both existing challenges and generate new revenue streams. Here are a few ways the Metaverse
        influences the retail sector.
      </p>
      <h2 className="text-primary font-semibold text-20px mb-2">Increased Revenue</h2>
      <p className="mb-4">
        According to a recent study on Forbes, using 3D models to replace static graphics can result in a 40% raise in
        conversion rates. The Metaverse offers customers the ability to experience products in a whole new way- by
        touching, feeling, and testing them virtually before making a purchase. Hence, building trust with customers can
        lead to increased revenue for brands.
      </p>
      <p className="mb-8">
        This would allow businesses to provide the same immersive experience without needing to have physical stores
        presence all over the world.
      </p>
      <h2 className="text-primary font-semibold text-20px mb-2">Interactive online shopping</h2>
      <p className="mb-8">
        Shopping online is often a lonely experience, which can lead customers to make various mistakes. The best advice
        you can follow most of the time is to base your decisions on what others have said about the product or service.
        With the metaverse, you can have a truly interactive shopping experience, where you can get feedback from other
        customers and consider their recommendations.
      </p>
      <h2 className="text-primary font-semibold text-20px mb-2">Better customer understanding</h2>
      <p className="mb-4">
        Brands can save money and time by utilizing the virtual world to understand their customer base and find out if
        their product is a good fit. Before launching a product, brands can create a collection of experiences that
        customers can enjoy virtually.
      </p>
      <p className="mb-8">
        The feedback from customers will help determine whether the product should continue to be produced, how much
        should be produced and when it should be produced. Consequently, brands can continue to have a loyal customer
        base and cut down on waste.
      </p>
      <h2 className="text-primary font-semibold text-20px mb-2">Community building</h2>
      <p className="mb-8">
        With the metaverse, communities can communicate more effectively and grow beyond the reach of regular followers.
        You can offer exclusive merchandise to your cult-like community of customers, who have helped your products grow
        in popularity time and time again. This will help to increase engagement and encourage others to join in.
      </p>
      <h2 className="text-primary font-semibold text-20px mb-2">New income streams</h2>
      <p className="mb-4">
        The metaverse wo&apos;t just be about improving physical businesses. It will provide a platform for innovation
        and collaboration across all industries. Many people assume that the metaverse is a different realm of reality
        where they might have an entirely digital second life. For that, they will be required to buy digital items that
        would assist them to live in this virtual world. Gamers are already accustomed to purchasing in-game accessories
        for their digital avatars.
      </p>
      <p className="mb-4">
        The metaverse provides an even more immersive experience by letting people work, attend events, and communicate
        with others online and thereby providing a more engaging and exciting way to live life. Consequently, businesses
        might start producing particular digital goods to meet the needs of users in the virtual world.
      </p>
      <p className="mb-8">
        Already positioned brands like Nike and Adidas are getting ahead of the competition by acquiring virtual world
        lands like The Sandbox and Roblox, respectively. Nike also has a product line that produces virtual sneakers.
        This product line is specifically tailored to produce high-quality virtual sneakers.
      </p>
      <h2 className="text-primary font-semibold text-20px mb-2">Reduction in costs</h2>
      <p className="mb-8">
        Since they intend to incorporate NFTs and digital currencies into their ecosystems, the majority of metaverses
        are created to run on distributed ledger technology like blockchain. This could lead to cost savings for both
        brands and customers. With brands in the metaverse being able to incorporate affordable digital currencies into
        their virtual stores, users will be able to save costs on cross-border transactions.
      </p>
      <h2 className="text-primary font-semibold text-20px mb-2">
        How does MetaTor enable users to have an unparalleled shopping experience?
      </h2>
      <p className="mb-4">
        The new reality of{' '}
        <a
          href="https://metator.com/static/media/metator-shopping.0598e55793ee1914dc49.mp4"
          target="_blank"
          rel="noreferrer"
        >
          <u>shopping in MetaTor</u>
        </a>{' '}
        will be a revolution for businesses and brands to have a wider reach in the metaverse. For customers, there
        could be nothing better than shopping in MetaTor and getting their orders delivered to their doorstep. The
        shopping experience is about to get a whole lot more fun and easy for everyone.
      </p>
      <p className="mb-4">
        In MetaTor, you&apos;ll not have to wait in long queues outside your favorite brand shops for any exclusive item
        purchase. The customer experience of shopping is going to be more engaging and convenient than ever before. No
        more traveling costs, CO2 emissions, or location issues, you can shop anything from anywhere in MetaTor. You
        don&apos;t have to visit any country or city physically in person to buy anything, MetaTor gives you the
        flexibility to shop from anywhere in the world, and have your order delivered right to your doorstep.
      </p>
    </div>
  );
}

export default CaseStudy5;

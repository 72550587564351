import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { css } from '@emotion/css';

function Toast({ isVisible = false, message = '', className = '', variant = 'primary', ...rest }) {
  const arbitraryStyles = css`
    border-color: var(--color-${variant});
  `;

  return (
    isVisible && (
      // custom css animation (named: fade-right), see in tailwind config.
      <div
        {...rest}
        className={clsx(
          'text-12px fixed bg-secondary right-[-380px] top-[140px] lg:top-[200px] z-[999] w-max border-b-2 py-[12px] px-[16px] animate-fade-right hidden',
          className,
          isVisible && '!block',
          arbitraryStyles
        )}
      >
        <span className="opacity-70">{message}</span>
      </div>
    )
  );
}

export default Toast;

Toast.propTypes = {
  isVisible: PropTypes.bool,
  message: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'warning', 'success', 'info', 'danger']),
};

import moment from 'moment';
import { Navigate } from 'react-router-dom';
import { AUTH_KEY } from '../constants/globals';

function ProtectedRoute({ element }) {
  const date = localStorage.getItem(AUTH_KEY);

  const isAuthenticated = Boolean(date && date > moment.utc().valueOf());

  if (!isAuthenticated) return <Navigate to="/password-protected" replace />;
  return element;
}

export default ProtectedRoute;

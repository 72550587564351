import React from 'react';
import Video from '../components/common/Video';
import Button from '../components/common/button/Button';
import BuiltIn from '../assets/images/built-in.png';
import BuilderPreview from '../assets/images/builder-ss.png';
import BuilderIcon from '../assets/images/builder-icon.png';
import TemplateStore from '../assets/images/template-store.png';
import MultipleStoreFronts from '../assets/images/multiple-store-fronts.png';
import NikeStore from '../assets/images/nike-store.png';
import TeslaStore from '../assets/images/tesla-store.png';
import SupportedTools from '../assets/images/supported-tools.png';
import LineOverlayLeft from '../assets/images/line-overlay-left.png';
import LineOverlayRight from '../assets/images/line-overlay-right.png';
import useToast from '../components/common/Toast/useToast';
import Toast from '../components/common/Toast/Toast';
import BuilderHeroBG from '../assets/videos/builder-hero-bg.mp4';
import MetaTags from '../components/common/MetaTags';

function Builder() {
  const { showToast, isVisible } = useToast();

  return (
    <>
      <MetaTags page="builder" />

      <section className="relative h-[600px] xl:h-[800px] overflow-hidden">
        <div className="flex items-end justify-center w-full h-full text-center absolute z-10 gradient-from-bottom">
          <div className="pb-[60px] 1xl:pb-[80px]">
            <h2 className="block text-24px mb-1">BUILD YOUR DREAMS WITH</h2>
            <h1 className="uppercase font-black mb-0">
              <span className="text-50px">
                METATOR <span className="text-primary">BUILDER</span>{' '}
              </span>
            </h1>
            <p className="mx-[10px] lg:w-3/4 lg:mx-auto">
              MetaTor builder empowers developers, artists, and all professionals from different fields to build
              anything anywhere in MetaTor.
            </p>
            <a href="https://forms.gle/XrVnHVYA16Y55nnf8" target="_blank" rel="noreferrer noopener">
              <Button className="min-w-[220px] mx-auto mt-[30px] mb-[16px]" title="Explore Builder" />
            </a>
            <button type="button" className="text-14px" onClick={showToast}>
              <u>Explore spaces</u>
            </button>
          </div>
        </div>

        <div className="relative">
          <Video videSource={BuilderHeroBG} autoplay loop muted />
          <div className="absolute w-full h-full top-0 gradient-from-bottom-50" />
        </div>
      </section>

      {/* metator builder */}
      <section className="bg-dark-gray px-[20px] lg:px-[60px] xl:px-[80px] 1xl:px-[180px] py-[40px] lg:py-[60px] xl:py-[100px] flex flex-col lg:flex-row items-center text-center lg:text-start">
        <div className="flex-1 xl:px-10 order-2 lg:order-1 mt-20 lg:mt-0 text-center">
          <img loading="lazy" className="sm:w-3/4 lg:w-[750px] mx-auto" src={BuiltIn} alt="built in" />
        </div>
        <div className="m-screen flex-1 lg:pl-5 order-1 lg:order-2">
          <h3 className="mb-3 text-20px">BUILD WITH US</h3>
          <h2 className="uppercase font-black mb-3 text-40px">
            METATOR <span className="text-primary">BUILDER</span>
          </h2>
          <p className="mb-[30px]">
            Powered by MetaTor Engine, MetaTor builder allows you to build anything in Metaverse using some World&apos;s
            leading 3D tools. Whether you&apos;re an artist or a builder, MetaTor is where your experience becomes
            reality.
          </p>
          <a href="https://forms.gle/XrVnHVYA16Y55nnf8" target="_blank" rel="noreferrer noopener">
            <Button className="min-w-[250px] mx-auto lg:m-0" title="Download" />
          </a>
        </div>
      </section>
      {/* build your imagination */}
      <section className="relative bg-dark-green px-[20px] lg:px-[60px] xl:px-[80px] 1xl:px-[180px] py-[40px] lg:py-[60px] xl:py-[100px] flex flex-col lg:flex-row items-center text-center lg:text-start">
        <div className="relative m-screen lg:w-5/12 z-10">
          <div className="flex items-center justify-center lg:justify-start mb-5">
            <img loading="lazy" src={BuilderIcon} alt="" />
            <h3 className="mb-0 text-20px font-bold ml-4">
              METATOR <span className="text-warning">BUILDER</span>
            </h3>
          </div>
          <h2 className="uppercase font-black mb-3 text-40px">
            BUILD YOUR <span className="text-warning">IMAGINATION</span>
          </h2>
          <p className="mb-[30px]">
            Make your dreams become reality and imagination become achievement with Metator Builder. Use any software
            you like, Metator Builder being open-source tool is integrated with world leading 3D tools to bring our
            metaverse to life. Whether it&apos;s your home or a freelance project, build with Metator Builder.
          </p>
          <a href="https://forms.gle/XrVnHVYA16Y55nnf8" target="_blank" rel="noreferrer noopener">
            <Button className="min-w-[250px] mx-auto lg:m-0" title="Builder access" variant="warning" />
          </a>
        </div>
        <div className="relative flex-1 px-5 lg:w-7/12 mt-12 lg:mt-0 z-10">
          <img loading="lazy" src={BuilderPreview} alt="" width="100%" />
        </div>
        <img
          loading="lazy"
          className="hidden lg:block w-[300px] xl:w-[420px] absolute left-[-40px]"
          src={LineOverlayLeft}
          alt=""
        />
        <img
          loading="lazy"
          className="hidden lg:block w-[300px] xl:w-[420px] absolute right-0"
          src={LineOverlayRight}
          alt=""
        />
      </section>
      {/* Setup a store */}
      <section className="bg-dark-gray px-[20px] lg:px-[60px] xl:px-[80px] 1xl:px-[180px] py-8 lg:py-16 1xl:py-20 text-center">
        <div className="m-screen flex-1">
          <h3 className="mb-3 text-20px">SET UP YOUR STORE</h3>
          <h2 className="uppercase font-black mb-3 text-40px">
            IN THE <span className="text-primary"> METATOR </span> SPACE
          </h2>
          <p className="mb-[30px] lg:w-[800px] mx-[20px] lg:mx-auto">
            Build and set up your store, brand franchise, or any commercial property in the open metaverse of Metator to
            create job opportunities and contribute into the business ecosystem.
          </p>
          <a href="https://forms.gle/XrVnHVYA16Y55nnf8" target="_blank" rel="noreferrer noopener">
            <Button className="min-w-[250px] mx-auto" title="Build store" />
          </a>
        </div>
      </section>
      {/* template store */}
      <section>
        <img loading="lazy" width="100%" src={TemplateStore} alt="" />
      </section>
      {/* build your imagination */}
      <section className="bg-dark-green px-[20px] lg:px-[60px] xl:px-[80px] 1xl:px-[180px] py-8 lg:pt-16 1xl:pt-20 text-center">
        <h3 className="mb-0 text-20px font-bold ml-4">JUST A CLICK AWAY</h3>
        <h2 className="uppercase font-black mb-3 text-40px">
          MULTIPLE <span className="text-primary">STORE </span>FRONTS
        </h2>
        <p className="mb-[30px] lg:w-3/4 lg:mx-auto">
          Built the metaverse. Use any tool you like. We&apos;re integrated with world leading 3D tools to bring our
          metaverse to life. Whether it&apos;s your home or a freelance project, build with Metator Engine.
        </p>
        <img loading="lazy" className="mx-auto" src={MultipleStoreFronts} alt="" />
      </section>
      {/* Setup a store */}
      <section className="min-h-[300px] bg-dark-gray px-[20px] lg:px-[60px] xl:px-[80px] 1xl:px-[180px] py-10 pb-20">
        <div className="m-screen flex-1 text-center mb-[40px] sm:mb-[60px] lg:mb-[80px]">
          <h2 className="uppercase font-black mb-3 text-40px break-words">
            TRANSFORMING <span className="text-primary"> RETAIL </span> EXPERIENCE
          </h2>
          <p className="lg:mb-[30px]">
            Experience of retail business is about to get digital and smart in the open metaverse of Metator.
          </p>
        </div>
        <div className="w-full lg:flex gap-[20px] mb-[80px]">
          <div className="flex-1">
            <div className="flex items-center mb-[13px]">
              <span className="text-24px font-bold">Shoe Store</span>
            </div>
            <img loading="lazy" src={NikeStore} width="100%" alt="" />
          </div>
          <div className="flex-1 mt-5 lg:mt-0">
            <div className="flex items-center mb-[13px]">
              <span className="text-24px font-bold">Virtual Showroom</span>
            </div>
            <img loading="lazy" src={TeslaStore} width="100%" alt="" />
          </div>
        </div>

        <div className="text-center">
          <h3 className="mb-[30px] lg:mb-[40px] text-12px font-bold">SUPPORTED TOOLS</h3>
          <img loading="lazy" src={SupportedTools} alt="supported tools" />
        </div>
      </section>

      {isVisible && <Toast isVisible={isVisible} message="This Feature is launching soon, stay tuned." />}
    </>
  );
}

export default Builder;

import React from 'react';
import SlickImageGallery from '../common/SlickImageGallery';
// # components
import Tabs from '../common/tabs/Tabs';
// uk
import UK1 from '../../assets/images/media/uk/1.jpg';
import UK2 from '../../assets/images/media/uk/2.jpg';
import UK3 from '../../assets/images/media/uk/3.jpg';
import UK4 from '../../assets/images/media/uk/4.jpg';
// uk
import IS1 from '../../assets/images/media/istanbul/1.jpg';
import IS2 from '../../assets/images/media/istanbul/2.jpg';
import IS3 from '../../assets/images/media/istanbul/3.jpg';
import IS4 from '../../assets/images/media/istanbul/4.jpg';
import IS5 from '../../assets/images/media/istanbul/5.jpg';
import IS6 from '../../assets/images/media/istanbul/6.jpg';
import IS7 from '../../assets/images/media/istanbul/7.jpg';
import IS8 from '../../assets/images/media/istanbul/8.jpg';
import IS9 from '../../assets/images/media/istanbul/9.jpg';
import IS10 from '../../assets/images/media/istanbul/10.jpg';
import IS11 from '../../assets/images/media/istanbul/11.jpg';
import IS12 from '../../assets/images/media/istanbul/12.jpg';
import IS13 from '../../assets/images/media/istanbul/13.jpg';
import IS15 from '../../assets/images/media/istanbul/15.jpg';
import IS16 from '../../assets/images/media/istanbul/16.jpg';
import IS17 from '../../assets/images/media/istanbul/17.jpg';
import IS18 from '../../assets/images/media/istanbul/18.jpg';
import IS19 from '../../assets/images/media/istanbul/19.jpg';
import IS20 from '../../assets/images/media/istanbul/20.jpg';
import IS21 from '../../assets/images/media/istanbul/21.jpg';
// tofas
import TO1 from '../../assets/images/media/tofas/1.jpg';
import TO2 from '../../assets/images/media/tofas/2.jpg';
import TO3 from '../../assets/images/media/tofas/3.jpg';
import TO4 from '../../assets/images/media/tofas/4.jpg';
import TO5 from '../../assets/images/media/tofas/5.jpg';
import TO6 from '../../assets/images/media/tofas/6.jpg';
import TO7 from '../../assets/images/media/tofas/7.jpg';

function Media() {
  const UKData = [
    {
      content: UK1,
      key: 'UK1',
    },
    {
      content: UK2,
      key: 'UK2',
    },
    {
      content: UK3,
      key: 'UK3',
    },
    {
      content: UK4,
      key: 'UK4',
    },
  ];
  const IstanbulData = [
    {
      content: IS1,
      key: 'IS1',
    },
    {
      content: IS2,
      key: 'IS2',
    },
    {
      content: IS3,
      key: 'IS3',
    },
    {
      content: IS4,
      key: 'IS4',
    },
    {
      content: IS5,
      key: 'IS5',
    },
    {
      content: IS6,
      key: 'IS6',
    },
    {
      content: IS7,
      key: 'IS7',
    },
    {
      content: IS8,
      key: 'IS8',
    },
    {
      content: IS9,
      key: 'IS9',
    },
    {
      content: IS10,
      key: 'IS10',
    },
    {
      content: IS11,
      key: 'IS11',
    },
    {
      content: IS12,
      key: 'IS12',
    },
    {
      content: IS13,
      key: 'IS13',
    },
    {
      content: IS15,
      key: 'IS15',
    },
    {
      content: IS16,
      key: 'IS16',
    },
    {
      content: IS17,
      key: 'IS17',
    },
    {
      content: IS18,
      key: 'IS18',
    },
    {
      content: IS19,
      key: 'IS19',
    },
    {
      content: IS20,
      key: 'IS20',
    },
    {
      content: IS21,
      key: 'IS21',
    },
  ];

  const TofasData = [
    {
      content: TO1,
      key: 'TO1',
    },
    {
      content: TO2,
      key: 'TO2',
    },
    {
      content: TO3,
      key: 'TO3',
    },
    {
      content: TO4,
      key: 'TO4',
    },
    {
      content: TO5,
      key: 'TO5',
    },
    {
      content: TO6,
      key: 'TO6',
    },
    {
      content: TO7,
      key: 'TO17',
    },
  ];

  return (
    <section className="mt-[60px] lg:mt-[120px] mx-[20px] lg:mx-[40px] pb-0 lg:pb-[20px] xl:pb-[80px]">
      <div className="text-center pb-[40px]">
        <h2 className="text-24px font-bold mb-[18px]">METATOR MEDIA</h2>
      </div>

      <Tabs
        className="mx-auto"
        centered
        contentClassName="text-center my-0"
        defaultCurrent="3"
        items={[
          {
            label: 'UK Property Show 2022',
            key: '1',
            // make sure all your images have same resolution. (16:9 preferred)
            content: (
              <div className="mb-[200px]">
                <p className="my-8">Ranked #1 in the UK Property Show 2022.</p>
                <SlickImageGallery images={UKData} className="w-full lg:w-3/4 1xl:w-[1096px] mx-auto" />
              </div>
            ),
          },
          {
            label: 'Istanbul Blockchain Week 2022',
            key: '2',
            content: (
              <div className="mb-[80px]">
                <p className="mx-auto max-w-[1200px] my-8">
                  Team MetaTor has received an immense response from the audience at Istanbul Blockchain Week. Everyone
                  at IBW22 has embraced the vision behind MetaTor and appreciated the efforts of team MetaTor.
                </p>
                <SlickImageGallery images={IstanbulData} className="w-full lg:w-3/4 1xl:w-[1096px] mx-auto" />
              </div>
            ),
          },
          {
            label: 'Dream IT - Event by TOFAŞ',
            key: '3',
            content: (
              <div className="mb-[80px]">
                <p className="mx-auto max-w-[1200px] my-8">
                  Team Metator was invited to the TOFAS ‘Dream IT’ event, held at TOFAS Academy Bursa, Turkey. It was
                  our pleasure to educate TOFAŞ’s employees about Metaverse & meet the officials of world’s leading
                  Automobile and IT organizations.
                </p>
                <SlickImageGallery images={TofasData} className="w-full lg:w-3/4 1xl:w-[1096px] mx-auto" />
              </div>
            ),
          },
        ]}
      />
    </section>
  );
}

export default Media;

import React from 'react';
import PropTypes from 'prop-types';
import StarFilled from '../../assets/icons/star-filled.svg';
import StarOutlined from '../../assets/icons/star-outlined.svg';

function Rating({ value, ...rest }) {
  return (
    <div {...rest}>
      {[1, 2, 3, 4, 5].map((_, i) => (
        <img loading="lazy" className="mr-[8px] last:mr-0" key={_} src={i < value ? StarFilled : StarOutlined} alt="" />
      ))}
    </div>
  );
}

export default Rating;

Rating.prototype = {
  value: PropTypes.number.isRequired,
};

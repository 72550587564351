import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

function Feedback({ error = null, time = 5000 }) {
  const [showError, setShowError] = useState(true);

  useEffect(() => {
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
    }, time);
  }, [error]);
  return showError && error && <p className="message error-message">{error.message || ''}</p>;
}

export default memo(Feedback);

Feedback.propTypes = {
  time: PropTypes.number,
};

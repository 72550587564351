import React from 'react';

function LiveIcon({ ...rest }) {
  return (
    <svg {...rest} xmlns="http://www.w3.org/2000/svg" width="89.838" height="35.093" viewBox="0 0 89.838 35.093">
      <g id="Group_12511" data-name="Group 12511" transform="translate(-366.053 -465.647)">
        <path
          id="Path_35013"
          data-name="Path 35013"
          d="M366.053,518.519H370.1v11.048h7.067v3.544H366.053Z"
          transform="translate(0 -33.909)"
          fill="#fff"
        />
        <rect
          id="Rectangle_3401"
          data-name="Rectangle 3401"
          width="4.148"
          height="2.918"
          transform="translate(379.8 483.984)"
          fill="var(--color-primary)"
        />
        <rect
          id="Rectangle_3402"
          data-name="Rectangle 3402"
          width="3.981"
          height="11.277"
          transform="translate(379.884 487.924)"
          fill="#fff"
        />
        <path
          id="Path_35014"
          data-name="Path 35014"
          d="M423.038,518.519h4.524l3.314,9.234,3.314-9.234h4.44l-5.92,14.7h-3.752Z"
          transform="translate(-36.547 -33.909)"
          fill="#fff"
        />
        <path
          id="Path_35015"
          data-name="Path 35015"
          d="M472.632,518.519h11.715v3.439h-7.734v2.21h7v3.189h-7v2.314h7.838v3.439H472.632Z"
          transform="translate(-68.355 -33.909)"
          fill="#fff"
        />
        <g id="Group_12512" data-name="Group 12512" transform="translate(2)">
          <g id="Group_12510" data-name="Group 12510" transform="translate(422.114 465.647)">
            <rect
              id="Rectangle_3403"
              data-name="Rectangle 3403"
              width="31"
              height="20"
              rx="3"
              transform="translate(0.4 15.093)"
              fill="var(--color-primary)"
            />
            <path
              id="Path_35011"
              data-name="Path 35011"
              d="M551.733,528.436H524.775a2.412,2.412,0,0,1-2.409-2.409V511.239a2.412,2.412,0,0,1,2.409-2.409h26.958a2.412,2.412,0,0,1,2.409,2.409v14.787A2.412,2.412,0,0,1,551.733,528.436Zm-26.958-18.294a1.1,1.1,0,0,0-1.1,1.1v14.787a1.1,1.1,0,0,0,1.1,1.1h26.958a1.1,1.1,0,0,0,1.1-1.1V511.239a1.1,1.1,0,0,0-1.1-1.1Z"
              transform="translate(-522.366 -493.343)"
              fill="var(--color-primary)"
            />
            <path
              id="Path_35012"
              data-name="Path 35012"
              d="M546.2,481.445a.654.654,0,0,1-.464-.192l-8.144-8.145a.656.656,0,0,1,.928-.928l7.681,7.681,14.022-14.022a.656.656,0,0,1,.928.928l-14.486,14.485A.653.653,0,0,1,546.2,481.445Z"
              transform="translate(-532.01 -465.648)"
              fill="#fff"
            />
          </g>
          <path
            id="Path_35016"
            data-name="Path 35016"
            d="M557.552,522.152l5.912,4.5a.738.738,0,0,1-.049,1.208l-5.912,3.778a.738.738,0,0,1-1.135-.621v-8.277A.738.738,0,0,1,557.552,522.152Z"
            transform="translate(-122.059 -36.142)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}

export default LiveIcon;

import React, { useState } from 'react';
import './hamburger-animated.css';
import clsx from 'clsx';

function HamburgerAnimatedIcon({ isActive = false }) {
  return (
    <div className={clsx('hamburger', isActive && 'is-active')} id="hamburger-1">
      <span className="line" />
      <span className="line" />
      <span className="line" />
    </div>
  );
}

export default HamburgerAnimatedIcon;

import clsx from 'clsx';
import { Link } from 'react-router-dom';
import React from 'react';
import { ReactComponent as RightOutlined } from '../../assets/icons/right-outlined.svg';

function BreadCrumb({ className = '', title = '', url = '', items = [] }) {
  return (
    <div className={clsx('min-h-[80px] py-[25px] text-20px font-medium', className)}>
      {items.length ? (
        <div className="flex items-center gap-x-2">
          <Link to={url}>
            <h1 className="border-b border-white">{title}</h1>
          </Link>
          {items.map(({ title, url, key }) => (
            <div className="flex items-center" key={key}>
              <span className="mx-3">
                <RightOutlined className="[&>path]:stroke-primary" />
              </span>
              <h2 className="text-16px">{title}</h2>
            </div>
          ))}
        </div>
      ) : (
        title
      )}
    </div>
  );
}

export default BreadCrumb;

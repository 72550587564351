import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

function SlickArrow({
  currentSlide,
  slideCount,
  borderColor,
  direction = '',
  icons = { prev: null, next: null },
  ...props
}) {
  return (
    <button
      {...props}
      className={clsx('slick-arrow', direction === 'next' ? 'slick-next' : 'slick-prev')}
      type="button"
    >
      {direction === 'next' ? icons.next : icons.prev}
    </button>
  );
}

export default SlickArrow;

SlickArrow.propTypes = {
  currentSlide: PropTypes.number,
  slideCount: PropTypes.number,
  borderColor: PropTypes.string,
  direction: PropTypes.oneOf(['next', 'prev']),
  icons: PropTypes.shape({
    prev: PropTypes.node,
    next: PropTypes.node,
  }),
};

import React from 'react';

function StepDivider() {
  return (
    <div className="grid grid-cols-3 gap-x-4">
      <div className="border-b-4 border-primary" />
      <div className="border-b-4 border-gray hover:border-primary" />
      <div className="border-b-4 border-gray hover:border-primary" />
    </div>
  );
}

export default StepDivider;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { getAssetsDynamically } from '../../utils/getAssetsDynamically';
import { METATOR_SERVICES } from '../../constants/globals';
import EXPERIENCE_ATTRIBUTES from '../../data/experiences.json';
// # components
import PrimarySlickSlider from '../common/PrimarySlickSlider';
import PrimaryCard from '../common/PrimaryCard';

function Explore({ type }) {
  const { color } = EXPERIENCE_ATTRIBUTES[type];

  const metatorServices = useMemo(
    () =>
      METATOR_SERVICES.reduce((previous, current) => {
        if (current.key !== type) {
          //  it'll exclude the current opened experience
          previous.push({
            ...current,
            title: (
              <>
                METATOR <span className={`text-${current.color} uppercase`}>{current.title}</span>
              </>
            ),
            poster: getAssetsDynamically(current.poster, 'images/experiences'), // it'll get the image dynamically
            description: (
              <p className="mb-[12px] mt-[16px] max-w-[800px] text-14px">
                {current.description}
                {current.isComingSoon && <span className="block my-2 text-primary">Coming soon</span>}
              </p>
            ),
          });
        }

        return previous;
      }, []),
    [type]
  );

  const navigate = useNavigate();

  return (
    <section className="mb-[40px] lg:mb-[90px] lg:w-[750px] xl:w-[1000px] 1xl:w-[1200px] 2xl:w-[1350px] 3xl:w-[1650px] mx-[20px] sm:mx-[30px] lg:mx-auto">
      <div className="text-center">
        <h3 className="uppercase text-20px font-black mt-4 mb-3">EXPLORE MORE EXPERIENCES</h3>
        <p className="w-full lg:w-1/2 mx-auto mb-[30px] lg:mb-[60px]">
          Experience the look and feel of Metator Experiences!
        </p>

        <div className="primary-slick-cards text-start">
          <PrimarySlickSlider color={color} slides={2} mobileSlides={1} autoplay autoplaySpeed={4000}>
            {metatorServices.map(({ description, title, poster, key, isComingSoon, extraContent }) => (
              <div key={key} className="p-3">
                <PrimaryCard
                  key={key}
                  description={description}
                  primaryTitle={title}
                  poster={poster}
                  extraContent={extraContent}
                  className="min-h-[220px] sm:min-h-[380px] cursor-pointer"
                  onClick={() => {
                    if (!isComingSoon) navigate(`/experiences/${key}`);
                  }}
                />
              </div>
            ))}
          </PrimarySlickSlider>
        </div>
      </div>
    </section>
  );
}

export default Explore;

Explore.propTypes = {
  type: PropTypes.string.isRequired,
};

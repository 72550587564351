import React from 'react';
// # assets
import LnIcon from '../../assets/icons/ln-icon-sm.svg';
import TwIcon from '../../assets/icons/tw-icon-sm.svg';
import CoreMember1 from '../../assets/images/meta-team/rao naveed.png';

function TeamCards({ teamName = '' }) {
  const teamsContent = {
    core: [
      {
        linkedin: 'https://www.linkedin.com/in/',
        twitter: 'https://twitter.com/',
        designation: 'Chief Executive Officer',
        description:
          'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam.',
        image: CoreMember1,
        title: (
          <p className="text-24px mb-2 font-secondary font-light uppercase">
            Rao <span className="font-bold">Naveed</span>
          </p>
        ),
        key: 1,
      },
      {
        linkedin: 'https://www.linkedin.com/in/',
        twitter: 'https://twitter.com/',
        designation: 'Chief Executive Officer',
        description:
          'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam.',
        image: CoreMember1,
        title: (
          <p className="text-24px mb-2 font-secondary font-light uppercase">
            Rao <span className="font-bold">Naveed</span>
          </p>
        ),
        key: 2,
      },
      {
        linkedin: 'https://www.linkedin.com/in/',
        twitter: 'https://twitter.com/',
        designation: 'Chief Executive Officer',
        description:
          'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam.',
        image: CoreMember1,
        title: (
          <p className="text-24px mb-2 font-secondary font-light uppercase">
            Rao <span className="font-bold">Naveed</span>
          </p>
        ),
        key: 3,
      },
    ],
    design: [
      {
        linkedin: 'https://www.linkedin.com/in/',
        twitter: 'https://twitter.com/',
        designation: 'Chief Executive Officer',
        description:
          'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam.',
        image: CoreMember1,
        title: (
          <p className="text-24px mb-2 font-secondary font-light uppercase">
            Rao <span className="font-bold">Naveed</span>
          </p>
        ),
        key: 1,
      },
      {
        linkedin: 'https://www.linkedin.com/in/',
        twitter: 'https://twitter.com/',
        designation: 'Chief Executive Officer',
        description:
          'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam.',
        image: CoreMember1,
        title: (
          <p className="text-24px mb-2 font-secondary font-light uppercase">
            Rao <span className="font-bold">Naveed</span>
          </p>
        ),
        key: 2,
      },
      {
        linkedin: 'https://www.linkedin.com/in/',
        twitter: 'https://twitter.com/',
        designation: 'Chief Executive Officer',
        description:
          'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam.',
        image: CoreMember1,
        title: (
          <p className="text-24px mb-2 font-secondary font-light uppercase">
            Rao <span className="font-bold">Naveed</span>
          </p>
        ),
        key: 3,
      },
    ],
    development: [
      {
        linkedin: 'https://www.linkedin.com/in/',
        twitter: 'https://twitter.com/',
        designation: 'Chief Executive Officer',
        description:
          'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam.',
        image: CoreMember1,
        title: (
          <p className="text-24px mb-2 font-secondary font-light uppercase">
            Rao <span className="font-bold">Naveed</span>
          </p>
        ),
        key: 1,
      },
      {
        linkedin: 'https://www.linkedin.com/in/',
        twitter: 'https://twitter.com/',
        designation: 'Chief Executive Officer',
        description:
          'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam.',
        image: CoreMember1,
        title: (
          <p className="text-24px mb-2 font-secondary font-light uppercase">
            Rao <span className="font-bold">Naveed</span>
          </p>
        ),
        key: 2,
      },
      {
        linkedin: 'https://www.linkedin.com/in/',
        twitter: 'https://twitter.com/',
        designation: 'Chief Executive Officer',
        description:
          'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam.',
        image: CoreMember1,
        title: (
          <p className="text-24px mb-2 font-secondary font-light uppercase">
            Rao <span className="font-bold">Naveed</span>
          </p>
        ),
        key: 3,
      },
    ],
  };

  const teamsHeader = {
    core: {
      title: 'Core',
      description: 'The master minds, the visionary, got it all moving.',
    },
    design: {
      title: 'Design',
      description:
        'The passionate and creative design team at Metator is dedicated to deliver the most high end graphics one could imagine!',
    },
    development: {
      title: 'Development',
      description:
        'The passionate development team at Metator is dedicated to deliver the high performance and secured products!',
    },
  };

  return (
    <>
      <div className="mb-[95px]">
        <h3 className="uppercase text-40px font-black">
          <span className="text-primary">{teamsHeader[teamName].title || ''}</span> Team
        </h3>
        <p>{teamsHeader[teamName].description || ''}</p>
      </div>
      <div className="grid grid-cols-2 gap-[120px]">
        {teamsContent[teamName] &&
          teamsContent[teamName].map(({ linkedin, twitter, designation, description, image, title, key }) => (
            <div className="flex mb-[40px]" key={key}>
              <div className="h-[62px] border-2 border-primary" />
              <img loading="lazy" width={219} height={219} src={image} alt="" />
              <div className="pt-7">
                <div className="flex justify-between">
                  {title}
                  <div>
                    <a className="mr-4" href={linkedin} rel="noreferrer noopener" target="_blank">
                      <img loading="lazy" src={LnIcon} alt="" />
                    </a>
                    <a href={twitter} rel="noreferrer noopener" target="_blank">
                      <img loading="lazy" src={TwIcon} target="_blank" alt="" />
                    </a>
                  </div>
                </div>
                <h4 className="mb-2 font-secondary">{designation}</h4>
                <p className="text-12px">{description}</p>
              </div>
            </div>
          ))}
      </div>
    </>
  );
}

export default TeamCards;

import React, { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import PlayButton from '../../assets/icons/play-icon.svg';
import PauseButton from '../../assets/icons/pause-icon.svg';
import VolumeHigh from '../../assets/icons/volume-high.svg';
import VolumeMute from '../../assets/icons/volume-mute.svg';
import ExpandIcon from '../../assets/icons/expand-icon.svg';
import useOutsideAlerter from '../../hooks/useOutsideAlerter';
import CircledCloseIcon from '../../assets/icons/circled-close-icon.png';

function VideoPlayer({
  description = null,
  primaryTitle = null,
  URL = '',
  poster,
  className = 'gradient-from-bottom-50',
  wrapperClassName = '',
  videSource = '',
  muted = false,
  ...rest
}) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isControlsVisible, setIsControlsVisible] = useState(false);
  const [isMute, setIsMute] = useState(muted);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const videoRef = useRef(null);

  const videoHandler = control => {
    setIsPlaying(control === 'play');

    if (control === 'play') {
      videoRef.current.play();
    } else if (control === 'pause') {
      videoRef.current.pause();
    }
  };

  const showControls = () => setIsControlsVisible(true);
  const hideControls = () => setIsControlsVisible(false);

  const volumeHandler = control => {
    setIsMute(control === 'mute');

    if (control === 'mute') {
      videoRef.current.muted = true;
    } else if (control === 'unmute') {
      videoRef.current.muted = false;
    }
  };

  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    setIsPlaying(false);
    videoRef.current.pause();
    videoRef.current.currentTime = 1;
  };

  const wrapperRef = useRef(null);
  const { isClickedOutside } = useOutsideAlerter(wrapperRef);
  const closeFullScreen = () => setIsFullScreen(false);

  useEffect(() => {
    if (isClickedOutside) closeFullScreen();
  }, [isClickedOutside]);

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
    rootMargin: '-250px',
  });

  useEffect(() => {
    if (!inView) {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  }, [inView]);

  return (
    <>
      <div
        ref={ref}
        className={clsx('relative overflow-hidden', wrapperClassName)}
        onMouseEnter={showControls}
        onMouseLeave={hideControls}
      >
        <div className="relative h-max flex items-center justify-center">
          <video ref={videoRef} className="w-full outline-none" loop poster={poster}>
            <track kind="captions" />
            <source src={videSource} type="video/mp4" />
          </video>
          {isPlaying && isControlsVisible && (
            <button onClick={handleFullScreen} type="button" className="absolute z-40 top-5 right-5">
              <img loading="lazy" className="w-[20px] sm:w-[30px]" src={ExpandIcon} alt="expand video" />
            </button>
          )}
          {!isPlaying && (
            <button onClick={() => videoHandler('play')} type="button" className="absolute z-40">
              <img loading="lazy" className="w-[24px] sm:w-[50px]" src={PlayButton} alt="play video" />
            </button>
          )}
          {isPlaying && isControlsVisible && (
            <button onClick={() => videoHandler('pause')} type="button" className="absolute z-40">
              <img loading="lazy" className="w-[24px] sm:w-[50px]" src={PauseButton} alt="pause video" />
            </button>
          )}

          {isControlsVisible && (
            <button
              onClick={() => volumeHandler(isMute ? 'unmute' : 'mute')}
              type="button"
              className="absolute left-8 top-3 md:top-5 z-40"
            >
              <img loading="lazy" className="w-[20px] md:w-[30px]" src={isMute ? VolumeMute : VolumeHigh} alt="" />
            </button>
          )}
        </div>

        <div
          className={clsx(
            'w-full h-full flex items-end bg-bottom bg-no-repeat bg-cover px-[20px] lg:px-[24px] xl:px-[40px] py-4 md:py-0 md:absolute bottom-0 text-center lg:text-start',
            className
          )}
          {...rest}
        >
          <div>
            <span className="text-24px font-bold uppercase">{primaryTitle}</span>
            <p className="mb-[0 mt-[12px] max-w-[800px]">{description}</p>
          </div>
        </div>
      </div>

      {/*  full screen with bg overlay */}
      {isFullScreen && (
        <div className="fixed z-[99] top-0 w-full h-full bg-[#000000C2] flex items-center justify-center">
          <div className="animate-zoom-in">
            <button type="button" onClick={closeFullScreen} className="w-full outline-none">
              <img
                loading="lazy"
                src={CircledCloseIcon}
                alt="close full screen"
                className="w-[24px] lg:w-[48px] ml-auto block m-2 lg:m-3"
              />
            </button>
            <video ref={wrapperRef} controls loop poster={poster}>
              <track kind="captions" />
              <source src={videSource} type="video/mp4" />
            </video>
          </div>
        </div>
      )}
    </>
  );
}

export default VideoPlayer;

VideoPlayer.propTypes = {
  description: PropTypes.string,
  URL: PropTypes.string,
  poster: PropTypes.string,
  minHeight: PropTypes.number,
};

import React from 'react';
import PropTypes from 'prop-types';
import EXPERIENCE_ATTRIBUTES from '../../data/experiences.json';
import { getAssetsDynamically } from '../../utils/getAssetsDynamically';

function Brands({ type = '' }) {
  const { brandDescription, brandsImage } = EXPERIENCE_ATTRIBUTES[type];

  return (
    <section className="mb-[90px] mx-[20px] sm:mx-[40px] lg:mx-[80px] xl:mx-0">
      <div className="xl:mx-auto text-center">
        <h3 className="uppercase text-20px font-black mt-4 mb-3">Select brands</h3>
        <p className="lg:w-3/4 mx-auto mb-[60px]">{brandDescription}</p>
        <img
          loading="lazy"
          className="blur-md"
          src={getAssetsDynamically(`list-${brandsImage}`, 'images/experiences')}
          alt=""
        />
      </div>
    </section>
  );
}

export default Brands;

Brands.propTypes = {
  type: PropTypes.string.isRequired,
};

import React from 'react';
import Video from '../common/Video';
import HeyPI from '../../assets/videos/heypi.mp4';

function VirtualAssistant() {
  return (
    <section className="lg:flex items-center relative overflow-hidden">
      <div className="w-full flex justify-center lg:justify-end mb-5">
        <Video videSource={HeyPI} autoplay loop />
      </div>
      <div className="px-[20px] sm:px-[40px] lg:px-[80px] xl:px-[140px] 1xl:px-[180px] 2xl:px-[220px] lg:absolute z-10 text-center lg:text-start my-5 mb-10 lg:m-0">
        <h2 className="text-24px uppercase mb-[10px] font-medium">YOUR VIRTUAL ASSISTANT</h2>
        <h3 className="text-70px font-bold uppercase mb-[16px]">
          Hey <span className="text-primary">PI</span>
        </h3>

        <p className="mb-[40px] lg:w-3/4">
          Meet Pi, your personal assistant to guide and help you in every possible way for an immersive and interactive
          metaverse experience.
        </p>
      </div>
    </section>
  );
}

export default VirtualAssistant;

import React from 'react';
import { useParams } from 'react-router-dom';
// # components
import Header from '../components/experience/Header';
import About from '../components/experience/About';
import Brands from '../components/experience/Brands';
import Explore from '../components/experience/Explore';
import Partners from '../components/experience/Partners';
import Store from '../components/experience/Store';
import Launch from '../components/experience/Launch';
import Subscribe from '../components/experience/Subscribe';
import MetaTags from '../components/common/MetaTags';
// import UseCases from "../components/experience/UseCases";
// import VideoShowOff from "../components/experience/VideoShowOff";

function Experience() {
  const { type } = useParams();

  return (
    <div>
      <MetaTags page={type} />

      {/* header - hero section */}
      <Header type={type} />

      {/* Videos showoff */}
      {/* <VideoShowOff type={type} /> */}

      {/* about the experience */}
      <About type={type} />

      {/* Brands / sponsors */}
      {type !== 'gaming' && <Brands type={type} />}

      {/* Use cases */}
      {/* <UseCases type={type} /> */}

      {/* Games store */}
      {type === 'gaming' && <Store />}

      {/* Explore more */}
      <Explore type={type} />

      {/* Technology partner  */}
      <Partners />

      {/* Launch */}
      {type === 'gaming' && <Launch />}

      {/* subscribe */}
      {type === 'gaming' && <Subscribe />}
    </div>
  );
}

export default Experience;

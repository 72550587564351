import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

function YoutubeEmbedded({
  videoId = 'gUARgocC5FU',
  title = 'YouTube video player',
  frameBorder = 0,
  allow = 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen',
  className = 'w-[600px] h-[450px]',
}) {
  return (
    <iframe
      className={clsx('bg-secondary', className)}
      src={`https://www.youtube.com/embed/${videoId}?rel=0`}
      title={title}
      frameBorder={frameBorder}
      allow={allow}
    />
  );
}

export default YoutubeEmbedded;

YoutubeEmbedded.propTypes = {
  videoId: PropTypes.string,
  title: PropTypes.string,
  frameBorder: PropTypes.number,
  allow: PropTypes.string,
};

// e.g:
// <YoutubeEmbedded
// className="mx-auto w-full xl:w-[885px] h-[200px] sm:h-[350px] lg:h-[450px]"
// height={}
// videoId="gUARgocC5FU"
// />
